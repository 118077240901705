import { Component, Input, OnInit, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { EmergencyNewsData } from '../interfaces/emergencyNewsData.interface';
import { NoticeBlurActive } from '../services/noticeBlurActive';
import { NoticeBlurActiveForModal } from '../services/noticeBlurActiveForModal';
import { PagedataService } from '../services/pagedata.service';
import { BlockComponent } from '../block/block.component';
import { BlockService } from '../services/block.service';

@Component({
  selector: 'app-emergencynews',
  templateUrl: './emergencynews.page.html',
  styleUrls: ['./emergencynews.page.scss']
})
export class EmergencyNewsPage implements OnInit {
  @Input() isShowPrivateMessage: boolean = false;
  @Input() isShopDetailNav: boolean = false;
  public showEmergencyNews: boolean = false;
  public emergencyNewsData: EmergencyNewsData = {
    showEmergencyNews: null,
    news: [],
    blockData: null,
    isFirstDisplayEmergencyNewsBlock: null
  };
  public blockData: any = [];
  public loadingImage = './assets/images/icons/loading-dot.png';
  public blockAreaListForAddBlock: any = {};
  public altSalonName = '';

  @Input() set pageData(value: any) {
    this.altSalonName = value.altSalonName;
    if (value.news !== null && !this.isShowPrivateMessage) {
      for (let i = 0; i < value.news.length; i++) {
        // 緊急ニュースを表示する条件
        // 店舗詳細モーダルを開いた状態なら、その店舗の緊急ニュースのみを表示する
        // 店舗詳細モーダルを開いていない状態（本部orアドバンス・ベーシック）なら、本部を含め取得できた全ての緊急ニュースを表示する
        let isDisplayEmargencyNews =
          (value.news[i].salonId === value.salonId && value.news[i].emergency && this.isShopDetailNav) ||
          (value.news[i].emergency && !this.isShopDetailNav);
        if (isDisplayEmargencyNews) {
          // 緊急ニュース表示フラグをtrueにする
          this.emergencyNewsData.showEmergencyNews = true;
          // ニュースの内容を表示用に加工して、表示用リストに追加する
          let showNews = value.news[i];
          showNews.bodyHtml = this.sanitizer.bypassSecurityTrustHtml(showNews.body);
          showNews.imageHtml = this.sanitizer.bypassSecurityTrustHtml(showNews.imageIframe);
          this.emergencyNewsData.news.push(showNews);
        }
      }
      if (this.emergencyNewsData.showEmergencyNews) {
        this.blockData = value.emergencyNewsBlockData;
        this.blockService.remakeBlockData(this.blockData);
        this.blockService.updateAltSalonName(value.altSalonName);
        this.emergencyNewsData.isFirstDisplayEmergencyNewsBlock = true;
        this.showEmergencyNews = true;
        if (this.isShopDetailNav) {
          this.noticeBlurActiveForModal.onNotifySharedDataChanged(true);
        } else {
          this.noticeBlurActive.onNotifySharedDataChanged(true);
        }
      }
    }
  }
  @Input() salonData: any;
  @ViewChildren('appBlock') appBlockElement: QueryList<BlockComponent>;
  @ViewChildren('videoIframeContent') videoIframeContentElement: QueryList<ElementRef>;

  constructor(
    public pds: PagedataService,
    private sanitizer: DomSanitizer,
    private noticeBlurActive: NoticeBlurActive,
    private noticeBlurActiveForModal: NoticeBlurActiveForModal,
    private elem: ElementRef,
    private blockService: BlockService
  ) {}

  ngOnInit() {}

  closeEmergencyNews() {
    this.showEmergencyNews = false;
    if (this.isShopDetailNav) {
      this.noticeBlurActiveForModal.onNotifySharedDataChanged(false);
    } else {
      this.noticeBlurActive.onNotifySharedDataChanged(false);
    }
  }

  reDisplay() {
    if (this.emergencyNewsData.showEmergencyNews && !this.isShowPrivateMessage) {
      this.showEmergencyNews = true;
      if (this.isShopDetailNav) {
        this.noticeBlurActiveForModal.onNotifySharedDataChanged(true);
      } else {
        this.noticeBlurActive.onNotifySharedDataChanged(true);
      }
      this.emergencyNewsData.isFirstDisplayEmergencyNewsBlock = true;
    }
  }

  // 動画を止める
  stopVideo() {
    // ページ内動画要素を取得する
    let videoIframeContentList = this.videoIframeContentElement.toArray();
    // 取得した結果、動画要素が存在した場合
    if (videoIframeContentList.length > 0) {
      // 各動画要素のinnerHTML(iframe部分)を再セットする
      for (let videoIframeContent of videoIframeContentList) {
        videoIframeContent.nativeElement.innerHTML = videoIframeContent.nativeElement.innerHTML;
      }
    }
    // 動画ブロックをリセットする
    let appBlockList = this.appBlockElement.toArray();
    for (let appBlock of appBlockList) {
      appBlock.resetVideoBlock();
    }
  }
}
