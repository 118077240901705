import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { ModalController } from '@ionic/angular';
import { MetaDataController } from '../services/metaDataController';
import { NavigationController } from '../services/navigationController';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {
  @Input() url: string;
  @Input() token: string;
  @Input() modal: any;
  public safeUrl: SafeResourceUrl;
  constructor(
    private sanitizer: DomSanitizer,
    private metaDataController: MetaDataController,
    private navigationController: NavigationController,
    private modalCtrl: ModalController,
    private elem: ElementRef
  ) {}

  ngOnInit() {
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }

  ionViewWillEnter() {
    // メッセージを店舗詳細（カードモーダル）以外から開いた場合
    if (!this.modal.presentingElement.classList.contains('card-modal')) {
      // cardモーダル表示用のクラスをつける
      this.navigationController.isOpenCardModal = true;
    }
  }

  ionViewDidEnter() {}

  ionViewWillLeave() {
    // メッセージを店舗詳細（カードモーダル）以外から開いた場合
    if (!this.modal.presentingElement.classList.contains('card-modal')) {
      // cardモーダル表示用のクラスを外す
      this.navigationController.isOpenCardModal = false;
    }
  }

  ionViewDidLeave() {}

  close() {
    this.modalCtrl.dismiss();
  }

  // モーダル内をスクロールした際に、モーダル自体のスクロールを止める
  stopDragging(event: TouchEvent): void {
    event.stopPropagation();
  }
}
