import { Component, OnDestroy, OnInit, ViewChild, ViewChildren, ElementRef, Input } from '@angular/core';
import { PagedataService } from '../services/pagedata.service';
import { MenuPageData } from '../interfaces/menuPageData.interface';
import { SalonData } from '../interfaces/salonData.interface';
import { ViewCategories } from '../interfaces/viewCategories.interface';
import { initMenuPageData, initSalonData } from '../initData/initData';
import { MetaDataController } from '../services/metaDataController';
import { NavigationController } from '../services/navigationController';
import { Router } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NoticeBlurActiveFromHeader } from '../services/noticeBlurActiveFromHeader';
import { NoticeBlurActiveFromHeaderForModal } from '../services/noticeBlurActiveFromHeaderForModal';
import { Subscription } from 'rxjs';
import { IonContent, Platform } from '@ionic/angular';
import { MobilefooterbuttonsComponent } from '../mobilefooterbuttons/mobilefooterbuttons.component';
import { WindowService } from '../services/windowService';
import { ShopDetailModalService } from '../services/shopDetailModal.service';
import { APP_ENABLED } from '../pagedata';
import { BlockService } from '../services/block.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.page.html',
  styleUrls: ['./menu.page.scss']
})
export class MenuPage implements OnInit, OnDestroy {
  @Input() shopName: string;
  @Input() isShopDetailNav: boolean = false;
  @Input() requestParams: any;

  @ViewChild(IonContent, { static: false }) content: IonContent;
  private subscription: Subscription;
  public pageData: MenuPageData;
  public blockData: any = [];
  public salonData: SalonData = initSalonData;
  public isShowPrivateMessage: boolean = false;
  public privateMsgHtml: SafeHtml;
  public blurActiveFlgFromHeader: boolean = false;
  public blurActiveModalFlgFromHeader: boolean = false;
  public pageDataForPub: MenuPageData; // プレビュー時使用
  public viewCategories: Array<ViewCategories>; //pageData.viewCategoriesを表示用に再編成したカテゴリ配列
  public isDisplayPrevPage: boolean = true;
  public isDisplayPubPage: boolean = true;
  public isFirstDisplayBlock: boolean = true; // ブロック要素の描画処理フラグ
  public isViewCategoryEnabled: boolean = false; // 表示カテゴリの利用有無
  public isDispTaxDesc: boolean = false; // 「※価格は税込表示です。」を表示するかどうか
  public isDispTimeDesc: boolean = false; // 「※時間は目安です。」を表示するかどうか
  public seeMoreButtonRequiredStatus = {}; // もっとみるボタン　が必要か？（カテゴリ、メニューごと）
  public seeMoreStatus = {}; // もっとみる状態（カテゴリ、メニューごと）
  public sideMenuSelectedCategoryId = -1; // 選択中カテゴリid -1は全表示
  public isWideCategorySelector; // カテゴリ選択サイドバーの幅
  public isUseCompanyMenu; // 会社メニュー画面（メニューブロックで生成）の利用有無
  @ViewChild(MobilefooterbuttonsComponent, { static: false })
  private mfbc: MobilefooterbuttonsComponent;

  public blockAreaListForAddBlock: any = {};
  public isResetIframe: boolean = false;
  @ViewChildren('appBlock') appBlockElement;
  @ViewChildren('videoIframeContent') videoIframeContentElement;
  public pageTitle: string;

  constructor(
    public pds: PagedataService,
    private metaDataController: MetaDataController,
    private sanitizer: DomSanitizer,
    private navigationController: NavigationController,
    private noticeBlurActiveFromHeader: NoticeBlurActiveFromHeader,
    private noticeBlurActiveFromHeaderForModal: NoticeBlurActiveFromHeaderForModal,
    public windowService: WindowService,
    private shopDetailModalService: ShopDetailModalService,
    private platform: Platform,
    private elem: ElementRef,
    public router: Router,
    private blockService: BlockService
  ) {
    this.pageData = initMenuPageData;
    this.viewCategories = [];
  }

  ngOnInit() {
    // イベント登録
    // サービスで共有しているデータが更新されたら発火されるイベントをキャッチする
    this.subscription = this.noticeBlurActiveFromHeader.sharedDataSource$.subscribe((msg) => {
      this.blurActiveFlgFromHeader = msg;
    });
    this.subscription = this.noticeBlurActiveFromHeaderForModal.sharedDataSource$.subscribe((msg) => {
      this.blurActiveModalFlgFromHeader = msg;
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ionViewWillEnter() {
    // 戻るボタン・スワイプバックでこのページについた場合はスクロール位置を修正（データが残っているのでデータの読み直しはしない）
    // 動画の読み直しのためsetBlockを実行する
    // 進むボタン時はコンポーネント初期化されてデータがないので、elseへ
    if (!this.navigationController.getClickedRouterLink() && this.pageData.pageTitle != null) {
    } else {
      const promise = this.loadData();
      promise
        .then((result) => {
          this.content !== undefined ? this.content.scrollToTop() : {};
          this.createSeeMoreButtonRequiredStatus();
          this.platform.resize.subscribe(() => {
            this.createSeeMoreButtonRequiredStatus();
          });
        })
        .catch((e) => console.error(e))
        .finally(() => {
          this.content !== undefined ? this.content.scrollToTop() : {};
          this.createSeeMoreButtonRequiredStatus();
          this.platform.resize.subscribe(() => {
            this.createSeeMoreButtonRequiredStatus();
          });
        });
    }
    this.navigationController.setClickedRouterLink(false);
    this.navigationController.refreshUrl();
  }

  ionViewWillLeave() {}

  ionViewDidLeave() {
    // iframe要素を全て再セットする（停止処理）
    this.stopVideo();
  }

  async loadData() {
    this.pageData = await this.pds.getMenuPageData(this.shopName);
    this.blockData = this.pageData.blockData;
    this.blockService.remakeBlockData(this.blockData);
    this.blockService.updateAltSalonName(this.pageData.altSalonName);
    this.salonData = await this.pds.getSalonData(this.shopName);

    if (!this.pageData.isDisplay && this.pds.getPreview()) {
      this.isDisplayPrevPage = false;
      this.pageDataForPub = await this.pds.getMenuPageData(this.shopName, false);
      if (!this.pageDataForPub.isDisplay) {
        this.isDisplayPubPage = false;
      }
    }
    // この条件の場合は非公開状態
    if ((!this.salonData.isPublic || this.salonData.publishedDate == null) && !this.pds.getPreview()) {
      this.isShowPrivateMessage = true;
    }
    if (!this.pageData.isDisplay && !this.pds.getPreview()) {
      if (this.isShopDetailNav) {
        this.shopDetailModalService.toHome(this.shopName);
      } else {
        this.navigationController.toHome();
      }
    }
    this.pageTitle = this.pageData['pageTitle'];
    if (this.pageData['isDisplayEndTitle']) {
      this.pageTitle = this.pageTitle + '｜' + this.pageData['endTitle'];
    }
    this.metaDataController.updateTitleDescription(this.pageTitle, this.pageData['pageDescription']);
    this.metaDataController.deleteWebSiteStructuredData();
    this.metaDataController.updateOGTitleDescription(
      this.pageTitle,
      this.pageData['pageDescription'],
      this.navigationController.getCurrentUrl()
    );

    // 表示カテゴリの分岐
    let noViewCategoryData =
      Object.keys(this.pageData.viewCategorySetting).length === 0 &&
      this.pageData.viewCategorySetting.constructor === Object;
    // 表示カテゴリ利用店舗用
    if (!noViewCategoryData && this.salonData.viewCategoryEnabled) {
      this.isViewCategoryEnabled = true;
      // 金額説明表示
      let dispPriceTreatment = this.pageData.viewCategoryTreatments.find((el) => el.price != null && el.price >= 0);
      if (dispPriceTreatment) {
        this.isDispTaxDesc = true;
      }
      // 所要時間説明表示
      let dispTimeTreatment = this.pageData.viewCategoryTreatments.find(
        (el) => el.timeRequired != null && el.timeRequired >= 0
      );
      if (dispTimeTreatment) {
        this.isDispTimeDesc = true;
      }
      // カテゴリ&メニューデータ
      this.viewCategories = [];
      for (const category of this.pageData.viewCategories) {
        const c = Object.assign({}, category);
        const menus = this.pageData.viewCategoryTreatments.filter(
          (menu) => menu.viewCategoryId === category.viewCategoryId
        );
        c.viewCategoryTreatments = [...menus];
        this.viewCategories.push(c);
      }
    }
    if (
      this.salonData.appEnabled === APP_ENABLED.PWA_APP_ENTERPRISE_COMPANY &&
      this.salonData.needMenuPage &&
      !this.salonData.isUseMenuFromCompany
    ) {
      this.isUseCompanyMenu = this.blockData['menu__conceptBlock'].some(
        (block) => block.blockType === 'referenceCategory' && block.title !== null && block.title !== ''
      );
    }
    console.log(this.isUseCompanyMenu);
  }

  clickLink(link, requestParams = {}) {
    if (this.isShopDetailNav) {
      // 店舗詳細モーダルで開いた場合
      this.shopDetailModalService.pushPage(this.shopName, link, requestParams);
    } else {
      // pageNameはモーダルでは使用しないので、パラメータを削除する
      delete requestParams['pageName'];
      // モーダルを閉じた状態でクリックした場合、通常のページ遷移を行う
      this.router.navigate(['/' + link], { queryParams: requestParams });
      this.navigationController.setClickedRouterLink(true);
    }
  }

  // 動画を止める
  stopVideo() {
    // ページ内動画要素を取得する
    let videoIframeContentList = this.videoIframeContentElement.toArray();
    // 取得した結果、動画要素が存在した場合
    if (videoIframeContentList.length > 0) {
      // 各動画要素のinnerHTML(iframe部分)を再セットする
      for (let videoIframeContent of videoIframeContentList) {
        videoIframeContent.nativeElement.innerHTML = videoIframeContent.nativeElement.innerHTML;
      }
    }
    // 動画ブロックをリセットする
    let appBlockList = this.appBlockElement.toArray();
    for (let appBlock of appBlockList) {
      appBlock.resetVideoBlock();
    }
  }

  // カテゴリセレクタサイドバーの開閉
  toggleCategorySelector(): void {
    this.isWideCategorySelector = !this.isWideCategorySelector;
  }

  // カテゴリ選択絞り込み表示
  selectCategory(id: number): void {
    this.sideMenuSelectedCategoryId = id;
    // メニュートップまでスクロール
    const scrollTargetElem: HTMLIonContentElement = document.querySelector('ion-content');
    const scrollToPositionElem: HTMLElement = document.querySelector('.menu_container');
    const headerHeight = 80; // SPサイズで算出
    if (scrollTargetElem && scrollToPositionElem) {
      const toY = scrollToPositionElem.offsetTop - headerHeight;
      scrollTargetElem.scrollToPoint(0, toY, 200);
    }
  }

  // 「もっとみる」ボタン表示状況データ作成
  createSeeMoreButtonRequiredStatus() {
    setTimeout(() => {
      const wrapperElems: NodeListOf<Element> = document.querySelectorAll('.category_bar, .menu_item');
      wrapperElems.forEach((item) => {
        const contentElem: HTMLElement = item.querySelector('.category_bar__content, .menu_item__content');
        const contentInnerElem: HTMLElement = item.querySelector(
          '.category_bar__content_inner, .menu_item__content_inner'
        );
        if (contentElem && contentInnerElem) {
          const contentElemH = contentElem.getBoundingClientRect().height;
          const contentInnerElemH = contentInnerElem.getBoundingClientRect().height;
          this.seeMoreButtonRequiredStatus[item.id] = contentInnerElemH > 0 && contentElemH < contentInnerElemH;
        }
      });
    }, 100);
  }
}
