import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { HttpClientModule } from '@angular/common/http';

import { DatePipe } from '@angular/common';

import { GcsApiProvider } from './services/GcsApiProvider';
import { GcsServiceProvider } from './services/GcsServiceProvider';
import { ReservationServiceProvider } from './services/ReservationServiceProvider';
import { HeaderModule } from './header/header.module';
import { EmergencyNewsPageModule } from './emergencynews/emergencynews.module';
import { HomeFooterModule } from './homefooter/homefooter.module';
import { FooterModule } from './footer/footer.module';
import { MobilefooterModule } from './mobilefooter/mobilefooter.module';
import { PrivatePageModule } from './private/private.module';
import { HomeToHeaderService } from './services/homeToHeader';
import { HomeToMobilefooterService } from './services/homeToMobilefooter';
import { NoticeBlurActive } from './services/noticeBlurActive';
import { NoticeBlurActiveForModal } from './services/noticeBlurActiveForModal';
import { WindowService } from './services/windowService';
import { NavigateService } from './services/navigate.service';
import { NoticeBlurActiveFromHeader } from './services/noticeBlurActiveFromHeader';
import { NoticeBlurActiveFromHeaderForModal } from './services/noticeBlurActiveFromHeaderForModal';
import { MyPageService } from './services/myPage.service';
import { ShopListMapModalService } from './services/shopListMapModal.service';
import { ShopDetailModalService } from './services/shopDetailModal.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { AffiliateNumberComponent } from './affiliatenumber/affiliatenumber.component';
import { FormsModule } from '@angular/forms';
import { HandShakeComponent } from './handshake/handshake.component';
import { GallerymodalModule } from './gallerymodal/gallerymodal.module';
import { NoticeOpenLinkFromAppCom } from './services/noticeOpenLinkFromAppCom';
// エンタープライズ開発の段階で最新版との差異が出たためコメントアウト
// ステージングから検証系に反映する際にコメントアウトを外す！忘れない！絶対！
// import { StorageService } from './services/StorageService';

import { ShopdetailmodalComponent } from './shopdetailmodal/shopdetailmodal.component';
import { ShoplistmapmodalComponent } from './shoplistmapmodal/shoplistmapmodal.component';

import { ShopdetailmodalModule } from './shopdetailmodal/shopdetailmodal.module';
import { ShoplistmapmodalModule } from './shoplistmapmodal/shoplistmapmodal.module';

import { LazyLoadImageModule } from 'ng-lazyload-image';
import { StorageService } from './services/StorageService';
import { LineNotificationModalComponent } from './line-notification-modal/line-notification-modal.component';
import { MessageComponent } from './message/message.component';
import { UpdateGoogleAnalyticsService } from './services/updateGoogleAnalyticsService';
import { NoticeCloseA2hs } from './services/noticeCloseA2hs';
import { CookieService } from 'ngx-cookie-service';

@NgModule({
  declarations: [
    AppComponent,
    AffiliateNumberComponent,
    HandShakeComponent,
    LineNotificationModalComponent,
    MessageComponent
  ],
  entryComponents: [
    AffiliateNumberComponent,
    HandShakeComponent,
    LineNotificationModalComponent,
    MessageComponent,
    ShoplistmapmodalComponent,
    ShopdetailmodalComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot({ swipeBackEnabled: false, innerHTMLTemplatesEnabled: true }),
    AppRoutingModule,
    HttpClientModule,
    ServiceWorkerModule.register('combined-sw.js', {
      enabled: environment.isServiceWorkerEnabled,
      registrationStrategy: 'registerImmediately'
    }),
    HeaderModule,
    LazyLoadImageModule,
    EmergencyNewsPageModule,
    HomeFooterModule,
    FooterModule,
    MobilefooterModule,
    PrivatePageModule,
    FormsModule,
    GallerymodalModule,
    ShoplistmapmodalModule,
    ShopdetailmodalModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    DatePipe,
    GcsServiceProvider,
    { provide: ReservationServiceProvider, useClass: GcsServiceProvider },
    GcsApiProvider,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    HomeToHeaderService,
    HomeToMobilefooterService,
    NoticeBlurActive,
    NoticeBlurActiveForModal,
    NoticeBlurActiveFromHeaderForModal,
    WindowService,
    NavigateService,
    NoticeBlurActiveFromHeader,
    MyPageService,
    ShopListMapModalService,
    ShopDetailModalService,
    Clipboard,
    StorageService,
    NoticeOpenLinkFromAppCom,
    UpdateGoogleAnalyticsService,
    NoticeCloseA2hs,
    CookieService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
