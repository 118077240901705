<div class="dialog">
  <div class="before-login" *ngIf="!isExpiredKey">
    <div class="body">
      <div class="error-affiliate" *ngIf="isFailureAuth">
        <img src="./../../assets/images/icons/alert-white.svg" alt="alert__img" width="30px" class="alert-icon" />
        <div>
          認証コードが間違っています。<br />
          最新のメッセージ内にある4桁の数字を入力してください。
        </div>
      </div>
      <div class="text">
        <div>この画面を表示するには、メッセージ内のリンクの下に表示されている4桁の認証コードを入力してください。</div>
      </div>
      <div class="affiliation-number">
        <div>
          <ion-input
            name="first"
            #first
            (keyup)="moveFocus($event, second, '')"
            [(ngModel)]="affiliateFirstNumber"
            type="tel"
            maxlength="1"
          >
          </ion-input>
        </div>
        <div>
          <ion-input
            name="second"
            #second
            (keyup)="moveFocus($event, third, first)"
            [(ngModel)]="affiliateSecondNumber"
            type="tel"
            maxlength="1"
          >
          </ion-input>
        </div>
        <div>
          <ion-input
            name="third"
            #third
            (keyup)="moveFocus($event, fourth, second)"
            [(ngModel)]="affiliateThirdNumber"
            type="tel"
            maxlength="1"
          >
          </ion-input>
        </div>
        <div>
          <ion-input
            name="fourth"
            #fourth
            (keyup)="moveFocus($event, '', third)"
            [(ngModel)]="affiliateFourthNumber"
            type="tel"
            maxlength="1"
          >
          </ion-input>
        </div>
      </div>
      <ion-button class="login-button" color="dark-button" expand="block" fill="solid" (click)="decision()"
        >ログイン</ion-button
      >
      <div class="text small">
        予約の変更・キャンセル、予約履歴などの閲覧、予約時のお客様情報の入力の省略ができます。<br />
        サロンの専用アプリから操作していただければ認証コードは不要です。
      </div>
    </div>
  </div>
  <div class="before-login" *ngIf="isExpiredKey">
    <div class="body">
      <div class="error-affiliate">
        <img src="./../../assets/images/icons/alert-white.svg" alt="alert__img" width="30px" class="alert-icon" />
        <div class="font-size-large">リンクの有効期限切れです。</div>
      </div>
      <div class="text">
        <div>リンクと4桁の認証コードは最新のメッセージ内にあるものをご利用ください。</div>
        <div>または、サロンの専用アプリから操作をしていただければ、ログイン不要でいつでも閲覧可能です。</div>
      </div>
      <!-- サロンのアイコンとサロン名（仮） -->
      <div class="app-icon" *ngIf="layoutData">
        <!-- <img src="./../../assets/images/app-icon-sample.png" width="80px" alt="" /> -->
        <img src="{{ layoutData.icon512 }}" width="80px" alt="logo_image" />
      </div>
      <div class="text small align-center">{{ salonData.name }}</div>
    </div>
  </div>
  <div class="footer-button">
    <ion-button color="light-button" fill="solid" class="skip-login" (click)="close()" *ngIf="pageName != 'reservation'"
      >もどる
    </ion-button>
    <div *ngIf="pageName == 'reservation'">
      <ion-button color="light-button" fill="solid" class="skip-login" (click)="close()">ログインせずに予約</ion-button>
      <div class="text small align-center">お客様情報の入力が再度必要になります。</div>
    </div>
  </div>
</div>
