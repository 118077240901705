import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { Routes, RouterModule } from '@angular/router';
import { ShoplistmapmodalComponent } from './shoplistmapmodal.component';
import { FormsModule } from '@angular/forms';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { GoogleMapsModule } from '@angular/google-maps';

@NgModule({
  declarations: [ShoplistmapmodalComponent],
  imports: [CommonModule, RouterModule, LazyLoadImageModule, IonicModule, FormsModule, GoogleMapsModule],
  exports: [ShoplistmapmodalComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ShoplistmapmodalModule {}
