<ion-content
  *ngIf="(pageData.isDisplay && isStaff && !isShowPrivateMessage) || !isDisplayPrevPage"
  class="overflow_scroll"
  id="window_top_staff_detail"
  name="blur_aria"
  [class.stop_scroll]="openModalFlg"
  [class.blur_active]="(!isShopDetailNav && (blurActiveFlg || blurActiveFlgFromHeader)) || (isShopDetailNav && (blurActiveModalFlg || blurActiveModalFlgFromHeader))"
  [scrollEvents]="true"
  [attr.data-page_title]="pageTitle"
  [attr.data-page_description]="pageData.pageDescription"
  (ionScroll)="mfbc.scrollEvent(content)"
  [class.__modalStyle__]="isShopDetailNav"
>
  <main class="main mainStaffDetail content_top">
    <!-- スタッフ詳細見出し -->
    <section class="staffDetailHeading sec">
      <div class="breadcrumbs" *ngIf="pageData.isDisplayBreadcrumbs; else elseIsBreadcurmbs">
        <ng-container *ngFor="let breadcrumbsData of pageData.breadcrumbsList">
          <span
            class="breadcrumbs_item home"
            *ngIf="breadcrumbsData.url == 'home'; else elseBreadcurmbsContent"
            (click)="clickLink( 'home' )"
            [class.__modalStyle__]="isShopDetailNav"
          ></span>
          <ng-template #elseBreadcurmbsContent>
            <label class="breadcrumbs_arrow" [class.__modalStyle__]="isShopDetailNav"> > </label>
            <span
              class="breadcrumbs_item"
              (click)="clickLink( breadcrumbsData.url )"
              [class.__modalStyle__]="isShopDetailNav"
              >{{ breadcrumbsData.title }}</span
            >
          </ng-template>
        </ng-container>
      </div>
      <ng-template #elseIsBreadcurmbs>
        <span href="javascript:void(0);" class="staffDetailHeading__backLink" (click)="navigationController.goBack()"
          ><</span
        >
      </ng-template>

      <div *ngIf="!isDisplayPrevPage" class="hiddenPageAlert">
        <div *ngIf="isDisplayPubPage" class="hiddenPageAlert__title__ForSave">このページは非表示予定です</div>
        <div *ngIf="!isDisplayPubPage" class="hiddenPageAlert__title__ForPublic">このページは現在非表示中です</div>
      </div>

      <div class="staffDetailHeading_container secContainer">
        <h2 class="staffDetailHeading__heading secHeading" [class.__modalStyle__]="isShopDetailNav">
          {{ pageData.title }}
        </h2>
      </div>
      <!-- /.staffDetailHeading_container -->
    </section>
    <!-- /.staffDetailHeading -->

    <!-- スタッフ詳細コンテンツ -->
    <section class="staffDetailContent sec">
      <div class="staffDetailContent_container secContainer">
        <div class="staffDetailContent__photo_name_area" [class.noPhoto]="!pageData.staffPerson.photo">
          <!-- スタッフ画像 -->
          <div
            *ngIf="pageData.staffPerson.photo"
            class="staffDetailContent__visual ratio_1x1 secContent staffDetailContent__photo_area"
          >
            <img
              class="staffDetailContent__inner_half"
              src="{{ pageData.staffPerson.photo }}"
              [alt]="pageData.altSalonName + 'の' + pageData.staffPerson.name + 'の写真'"
            />
          </div>
          <!-- /.staffDetailContent__visual -->

          <!-- スタッフ情報 -->
          <div class="staffDetailContent__info secContent staffDetailContent__name_area">
            <h3 class="staffDetailContent__info__name jpfont" [class.__modalStyle__]="isShopDetailNav">
              {{ pageData.staffPerson.name }}
            </h3>
            <h3
              class="staffDetailContent__info__staffPosition jpfont"
              [class.__modalStyle__]="isShopDetailNav"
              *ngIf="pageData.staffPerson.staffPosition"
            >
              {{ pageData.staffPerson.staffPosition }}
            </h3>

            <p
              class="staffDetailContent__info__message"
              [class.__modalStyle__]="isShopDetailNav"
              [innerHTML]="pageData.staffPerson.introduction"
              *ngIf="pageData.staffPerson.introduction"
            ></p>
            <h3
              *ngIf="pageData.staffPerson.goodPointTitle && pageData.staffPerson.goodPoint"
              class="staffDetailContent__info__title jpfont"
              [class.__modalStyle__]="isShopDetailNav"
            >
              {{ pageData.staffPerson.goodPointTitle }}
            </h3>
            <p
              *ngIf="pageData.staffPerson.goodPointTitle && pageData.staffPerson.goodPoint"
              class="staffDetailContent__info__message"
              [innerHTML]="pageData.staffPerson.goodPoint | htmlSanitize"
              [class.__modalStyle__]="isShopDetailNav"
            ></p>

            <!-- 個人SNSボタン -->
            <div
              class="staffDetailContent__info__snsIcons"
              *ngIf="pageData.staffPerson.individualInstagramLink || pageData.staffPerson.individualTwitterLink"
            >
              <ul class="staffDetailContent__info__snsIcons__list">
                <li
                  class="staffDetailContent__info__snsIcons__item"
                  *ngIf="pageData.staffPerson.individualInstagramLink"
                >
                  <a
                    href="{{ pageData.staffPerson.individualInstagramLink }}"
                    class="staffDetailContent__info__snsIcons__link"
                    target="_brank"
                  >
                    <img
                      [defaultImage]="loadingImage"
                      [lazyLoad]="instagram_icon"
                      class="staffDetailContent__info__snsIcons__img staffDetailContent__info__snsIcons__img__instagram"
                      alt="instagram_icon"
                    />
                  </a>
                </li>
                <li class="staffDetailContent__info__snsIcons__item" *ngIf="pageData.staffPerson.individualTwitterLink">
                  <a
                    href="{{ pageData.staffPerson.individualTwitterLink }}"
                    class="staffDetailContent__info__snsIcons__link"
                    target="_brank"
                  >
                    <img
                      [defaultImage]="loadingImage"
                      [lazyLoad]="twitter_icon"
                      class="staffDetailContent__info__snsIcons__img staffDetailContent__info__snsIcons__img__twitter"
                      alt="twitter_icon"
                    />
                  </a>
                </li>
              </ul>
            </div>

            <!-- スタッフ指名予約 -->
            <div
              class="staffDetailContent__btn"
              *ngIf="
                pageData.staffPerson.isDisplayReserveLink &&
                pageData.staffPerson.reserveDealType != 1 &&
                !pageData.staffPerson.invalidPublic
              "
            >
              <span
                (click)="clickBooking(pageData.staffPerson)"
                class="staffDetailContent__btn__link btn_bookShimei"
                [class.__modalStyle__]="isShopDetailNav"
              >
                指名予約する
              </span>
            </div>
          </div>
        </div>

        <div class="staffDetailContent__profile_area" [class.noPhoto]="!pageData.staffPerson.photo">
          <!-- スタッフ情報 -->
          <p
            class="staffDetailContent__info__message"
            [class.__modalStyle__]="isShopDetailNav"
            [innerHTML]="pageData.staffPerson.introduction"
            *ngIf="pageData.staffPerson.introduction"
          ></p>

          <h3
            *ngIf="pageData.staffPerson.goodPointTitle && pageData.staffPerson.goodPoint"
            class="staffDetailContent__info__title jpfont"
            [class.__modalStyle__]="isShopDetailNav"
          >
            {{ pageData.staffPerson.goodPointTitle }}
          </h3>
          <p
            *ngIf="pageData.staffPerson.goodPointTitle && pageData.staffPerson.goodPoint"
            class="staffDetailContent__info__message"
            [innerHTML]="pageData.staffPerson.goodPoint | htmlSanitize"
            [class.__modalStyle__]="isShopDetailNav"
          ></p>

          <!-- 個人SNSボタン -->
          <div
            class="staffDetailContent__info__snsIcons"
            *ngIf="pageData.staffPerson.individualInstagramLink || pageData.staffPerson.individualTwitterLink"
          >
            <ul class="staffDetailContent__info__snsIcons__list">
              <li class="staffDetailContent__info__snsIcons__item" *ngIf="pageData.staffPerson.individualInstagramLink">
                <a
                  href="{{ pageData.staffPerson.individualInstagramLink }}"
                  class="staffDetailContent__info__snsIcons__link"
                  target="_brank"
                >
                  <img
                    [defaultImage]="loadingImage"
                    [lazyLoad]="instagram_icon"
                    class="staffDetailContent__info__snsIcons__img staffDetailContent__info__snsIcons__img__instagram"
                    alt="instagram_icon"
                  />
                </a>
              </li>
              <li class="staffDetailContent__info__snsIcons__item" *ngIf="pageData.staffPerson.individualTwitterLink">
                <a
                  href="{{ pageData.staffPerson.individualTwitterLink }}"
                  class="staffDetailContent__info__snsIcons__link"
                  target="_brank"
                >
                  <img
                    [defaultImage]="loadingImage"
                    [lazyLoad]="twitter_icon"
                    class="staffDetailContent__info__snsIcons__img staffDetailContent__info__snsIcons__img__twitter"
                    alt="twitter_icon"
                  />
                </a>
              </li>
            </ul>
          </div>

          <!-- スタッフ指名予約 -->
          <div
            class="staffDetailContent__btn"
            *ngIf="
              pageData.staffPerson.isDisplayReserveLink &&
              pageData.staffPerson.reserveDealType != 1 &&
              !pageData.staffPerson.invalidPublic
            "
          >
            <span
              (click)="clickBooking(pageData.staffPerson)"
              class="staffDetailContent__btn__link btn_bookShimei"
              [class.__modalStyle__]="isShopDetailNav"
            >
              指名予約する
            </span>
          </div>
        </div>
      </div>
      <!-- /.staffDetailContent_container -->
    </section>
    <!-- /.staffDetailContent -->

    <!-- ギャラリーエリア -->
    <section *ngIf="0 < pageData.staffPerson.gallery.length" class="staffDetailGallery sec">
      <div class="staffDetailGallery_container secContainer">
        <h2 class="staffDetailGallery__heading" [class.__modalStyle__]="isShopDetailNav">
          {{ pageData.galleryPage.title }}
        </h2>

        <div class="staffDetailGallery__portfolio secContent">
          <ul class="staffDetailGallery__portfolio__list">
            <li
              class="staffDetailGallery__portfolio__item aspectImg ratio_1x1"
              [class.__modalStyle__]="isShopDetailNav"
              *ngFor="let c of pageData.staffPerson.gallery"
            >
              <div (click)="galleryModal.openModal(c)" class="staffDetailGallery__portfolio__link aspectImg__inner">
                <picture>
                  <source type="image/webp" [attr.defaultImage]="loadingImage" [attr.lazyLoad]="c.webpUrl" />
                  <img
                    [defaultImage]="loadingImage"
                    [lazyLoad]="c.url"
                    class="staffDetailGallery__portfolio__link__inner"
                    [class.disp_cover]="pageData.galleryDisplayImageCover"
                    [alt]="pageData.altSalonName + 'の' + pageData.staffPerson.name + 'のギャラリー' + c.id + '写真'"
                  />
                </picture>
              </div>
            </li>
            <li class="staffDetailGallery__portfolio__dummy"></li>
          </ul>
        </div>
        <!-- /.staffDetailGallery__portfolio -->

        <!-- <div *ngIf="galleryImageHasNext" class="staffDetailGallery__btn secContent"> -->
        <!--   <a (click)="more()" class="staffDetailGallery__btn__link btn_moreSquare">もっとみる</a> -->
        <!-- </div> -->
        <!-- /.staffDetailGallery__btn -->
      </div>
      <!-- /.staffDetailGallery-container -->
    </section>
    <!-- /.staffDetailGallery -->
  </main>
  <!-- /.mainStaffDetail -->

  <!-- フッター -->
  <app-footer [shopName]="this.shopName" [isShopDetailNav]="isShopDetailNav" class="appFooter"></app-footer>
</ion-content>

<!-- モーダル -->
<section class="galleryModal modal" id="staffDetail_modal" [class.open_modal]="openModalFlg">
  <app-gallerymodal
    [pageData]="pageData"
    (modalcontrol)="modalcontrol($event)"
    [dispModalStaffDetail]="false"
    [modalid]="'staffDetailModal__content'"
    [isShopDetailNav]="isShopDetailNav"
    #galleryModal
  ></app-gallerymodal>
</section>
<!-- /.galleryModal -->

<app-private
  class="ion-page"
  *ngIf="isShowPrivateMessage"
  [shopName]="this.shopName"
  [isShopDetailNav]="isShopDetailNav"
  [privateMsgHtml]="pageData.privateMsg | htmlSanitize"
></app-private>
<app-mobilefooterbuttons (scrollToTopEvent)="windowService.scrollToTop(content)"></app-mobilefooterbuttons>
<app-mobilefooter
  *ngIf="!isShowPrivateMessage && !isShopDetailNav"
  class="applike"
  [blurActiveFlg]="blurActiveFlg || blurActiveFlgFromHeader"
  [shopName]="this.shopName"
  [isShopDetailNav]="isShopDetailNav"
></app-mobilefooter>
