import { Component, OnInit, ViewChildren, OnDestroy, Input, QueryList, ElementRef } from '@angular/core';
import { PagedataService } from '../services/pagedata.service';
import { NavigationEnd, Router } from '@angular/router';
import { ModalController, Platform } from '@ionic/angular';
import { NavigationController } from '../services/navigationController';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { RequestParamsService } from '../services/requestParams.service';
import { BlockData } from '../interfaces/blockData.interface';
import { DomainRelatedAccountData } from '../interfaces/domainRelatedAccountData.interface';
import { ShopDetailModalService } from '../services/shopDetailModal.service';
import { Subscription } from 'rxjs';
import { AccountService } from '../services/account.service';
import { WindowService } from '../services/windowService';
import { NavigateService } from '../services/navigate.service';
import { BlockService } from '../services/block.service';
import { register } from 'swiper/element/bundle';
register();

@Component({
  selector: 'app-block',
  templateUrl: '../block/block.component.html',
  styleUrls: ['../block/block.component.scss']
})
export class BlockComponent implements OnInit, OnDestroy {
  @Input() shopName: string;
  @Input() isShopDetailNav: boolean = false;
  @Input() blockData: any;
  @Input() position: string;
  @Input() isUseCompanyMenu: boolean = false;
  @ViewChildren('videoBlock') videoBlockElement: QueryList<ElementRef>;

  public isStandAlone: boolean = false;
  public sideMenuSelectedCategoryId = -1; // 選択中カテゴリid -1は全表示
  public isWideCategorySelector: boolean = false; // カテゴリ選択サイドバーの幅
  public seeMoreButtonRequiredStatus = {};
  private resizeSubscription: Subscription;
  private isCompany: boolean = false;
  private salonAccountName: string = null;

  constructor(
    public pds: PagedataService,
    private navigationController: NavigationController,
    private router: Router,
    private requestParamsService: RequestParamsService,
    public shopDetailModalService: ShopDetailModalService,
    private sanitizer: DomSanitizer,
    private platform: Platform,
    public accountService: AccountService,
    private windowService: WindowService,
    private navigateService: NavigateService,
    public blockService: BlockService
  ) {}

  async ngOnInit() {
    this.resizeSubscription = this.platform.resize.subscribe(() => {
      this.seeMoreButtonRequiredStatus = {};
    });
    this.isCompany = this.pds.getIsCompany();
  }

  ngOnDestroy() {
    if (this.resizeSubscription) {
      this.resizeSubscription.unsubscribe();
    }
  }

  getImageIframeHTML(imageIframe: string) {
    let imageIframeHTML: SafeHtml;
    imageIframeHTML = this.sanitizer.bypassSecurityTrustHtml(imageIframe);
    return imageIframeHTML;
  }

  // 外部リンクボタンブロック
  async clickUrlButtonBlock(buttonBlock: HTMLElement, block: BlockData) {
    // 登録内容からURLを生成する
    let urlString = '';
    if (Number(block.buttonUrlHeadKind) === 1) {
      urlString += 'http://';
    } else if (Number(block.buttonUrlHeadKind) === 2) {
      urlString += 'https://';
    }
    urlString += block.buttonUrlBody;
    // 外部リンク遷移を行う
    await this.navigateService.navigateExternalUrl(urlString, block.buttonIsOpenNewTab);
  }

  // 内部リンクボタンブロック
  async clickInternalUrlButtonBlock(buttonBlock: HTMLElement, block: BlockData) {
    var $companyContents = buttonBlock.closest('.companyContents');
    if ($companyContents) {
      await this.navigateService.navigateUrlForCompanyContents(
        block.buttonInternalUrl,
        block.buttonIsOpenNewTab,
        $companyContents.attributes['data-salon_id'].value
      );
    } else {
      // 内部リンク遷移を行う
      this.navigateService.navigateInternalUrl(block.buttonInternalUrl, block.buttonIsOpenNewTab);
    }
  }

  // 店舗リンククリック関数
  clickShopLinkBlock(shopLinkBlock: HTMLElement, link: string) {
    // linkで渡ってきたパラメータを分解する
    let linkParams = link.split('&');
    let params: { shopDetail: string; page: string } = {
      shopDetail: null,
      page: null
    };
    let requestParams = this.requestParamsService.getRequestParams();
    for (var linkParam of linkParams) {
      (<any>params)[linkParam.split('=')[0]] = linkParam.split('=')[1];
    }
    if (this.isShopDetailNav) {
      // 店舗詳細モーダルを開いている場合
      // 本部のコンテンツ内で店舗リンクを設置して、店舗詳細モーダル内で店舗リンクをクリックした場合
      if (params['shopDetail'] === this.shopDetailModalService.shopName) {
        // 店舗リンクの店舗が、開いている店舗詳細モーダルの店舗と同一の場合
        // 店舗詳細モーダル内でページ追加を行う
        this.shopDetailModalService.pushPage(this.shopDetailModalService.shopName, params['page']);
      } else {
        // 店舗リンクの店舗が、開いている店舗詳細モーダルの店舗と異なる場合
        // 本部の内部リンクの形式に変換し、外部リンクとして処理
        var $companyContents = shopLinkBlock.closest('.companyContents');
        // 開いているページが本部の場合は同一タブ、本部でない場合は新しいタブで開く
        // （本部でない場合は、アドバンスページから開いた店舗詳細モーダルにて、本部に対する店舗リンクを開いているので別タブで開く）
        let isOpenNewTab = requestParams.isCompanyForNonCustomDomain ? false : true;
        this.navigateService.navigateUrlForCompanyContents(
          '/home?' + link,
          isOpenNewTab,
          $companyContents.attributes['data-salon_id'].value
        );
      }
    } else {
      // 店舗詳細モーダルを開いていない場合
      if (requestParams.salonOrDomainName === params['shopDetail']) {
        // 開いているページの店舗と、店舗リンクの店舗が同一の場合、内部リンクとしてページ遷移する
        // 店舗リンクは「新しいタブで開く」のチェックがないので、同一タブで遷移する
        this.navigateService.navigateInternalUrl(params['page'], false);
      } else {
        // 開いているページの店舗（本部）と、店舗リンクの店舗が異なる場合、店舗リンクとして処理する
        // 分解したパラメータをセットして、店舗詳細モーダルを起動する
        this.shopDetailModalService.openShopDetailModal(params['shopDetail'], params['page'], {
          pageName: params['page']
        });
      }
    }
  }

  // ２列レイアウトの列番号配列を生成
  arrayColumnNumList(columnNum: string): any[] {
    let maxNum = Number(columnNum.split('-')[0]);
    let numList = [];
    for (let i = 1; i <= maxNum; i++) {
      numList.push(maxNum + '-' + i);
    }
    return numList;
  }

  // 動画ブロックの再セット（再生中の動画を一旦止めて、同じ要素をセットする）
  resetVideoBlock() {
    let videoBlockList = this.videoBlockElement.toArray();
    if (videoBlockList.length > 0) {
      for (let videoBlock of videoBlockList) {
        videoBlock.nativeElement.innerHTML = videoBlock.nativeElement.innerHTML;
      }
    }
  }

  // カテゴリ選択絞り込み表示
  selectCategory(id: number): void {
    this.sideMenuSelectedCategoryId = id;
    // メニュートップまでスクロール
    const scrollTargetElem: HTMLIonContentElement = document.querySelector('ion-content');
    const scrollToPositionElem: HTMLElement = document.querySelector('.menu_container');
    const headerHeight = 80; // SPサイズで算出
    if (scrollTargetElem && scrollToPositionElem) {
      const toY = scrollToPositionElem.offsetTop - headerHeight;
      scrollTargetElem.scrollToPoint(0, toY, 200);
    }
  }

  // カテゴリセレクタサイドバーの開閉
  toggleCategorySelector(): void {
    this.isWideCategorySelector = !this.isWideCategorySelector;
  }

  // Googleクチコミの「もっとみる」ボタンクリック時
  clickGoogleReviewMore(commentElem: HTMLElement) {
    if (commentElem.classList.contains('open')) {
      commentElem.classList.remove('open');
      commentElem.style.height = commentElem.dataset.baseHeight + 'px';
    } else {
      commentElem.classList.add('open');
      commentElem.style.height = commentElem.scrollHeight + 'px';
    }
  }

  initGoogleReviewBlock(reviewSwiper: any, prevBtn: any, nextBtn: any) {
    // スライダーの設定
    reviewSwiper.swiper.params = {
      ...reviewSwiper.swiper.params,
      spaceBetween: 10,
      breakpointsBase: 'window',
      breakpoints: {
        0: {
          slidesPerView: 1,
          centeredSlides: true,
          allowTouchMove: true
        },
        768: {
          slidesPerView: 2,
          centeredSlides: false,
          allowTouchMove: true
        },
        1367: {
          slidesPerView: 2,
          centeredSlides: false,
          allowTouchMove: false
        }
      }
    };
    // スライダーの次へ、前へボタンのクリックイベントを設定
    reviewSwiper.swiper.navigate = {
      nextEl: nextBtn,
      prevEl: prevBtn
    };
    reviewSwiper.swiper.navigate.nextEl.addEventListener('click', () => {
      reviewSwiper.swiper.slideNext();
    });
    reviewSwiper.swiper.navigate.prevEl.addEventListener('click', () => {
      reviewSwiper.swiper.slidePrev();
    });

    // スライダーの変更時にスライドのチェックを行う
    reviewSwiper.swiper.on('activeIndexChange', function (swiper) {
      checkSlide(swiper);
    });
    // スライダーの変更後にスライドの開閉チェックを行う
    reviewSwiper.swiper.on('slideChangeTransitionEnd', function (swiper) {
      // スライドを取得
      let slides = swiper.slides;
      // 表示枚数が2枚の場合
      if (swiper.params.slidesPerView === 2) {
        // swiper-slide-activeクラスとswiper-slide-nextクラス以外のスライドを取得
        slides = slides.filter((slide: HTMLElement) => {
          return !slide.classList.contains('swiper-slide-active') && !slide.classList.contains('swiper-slide-next');
        });
      }
      // 各スライドのレビュー内容を閉じる
      for (let slide of slides) {
        const googleReviewComment = Array.from(slide.childNodes[0].childNodes).find(
          (node: HTMLElement) => node.classList && node.classList.contains('googleReview__item__comment')
        ) as HTMLElement;
        if (googleReviewComment.classList.contains('open')) {
          googleReviewComment.classList.remove('open');
          googleReviewComment.style.height = googleReviewComment.dataset.baseHeight + 'px';
        }
      }
    });
    // スライド数変更時（初回の要素生成時）
    reviewSwiper.swiper.on('slidesLengthChange', function (swiper) {
      // スライドの高さを設定
      let slides = reviewSwiper.swiper.slides;
      for (let slide of slides) {
        const googleReviewComment = Array.from(slide.childNodes[0].childNodes).find(
          (node: HTMLElement) => node.classList && node.classList.contains('googleReview__item__comment')
        ) as HTMLElement;
        // ベースの高さを設定
        googleReviewComment.dataset.baseHeight = String(googleReviewComment.clientHeight);
        googleReviewComment.style.height = googleReviewComment.clientHeight + 'px';
        // クチコミ内容が省略されている場合、ellipsisクラスを追加
        if (googleReviewComment.scrollHeight > googleReviewComment.clientHeight) {
          googleReviewComment.classList.add('ellipsis');
        }
      }
      // ナビゲーションボタンの位置を設定
      const navigationTop = reviewSwiper.swiper.el.parentNode.clientHeight / 2;
      reviewSwiper.swiper.navigate.nextEl.style.top = navigationTop + 'px';
      reviewSwiper.swiper.navigate.prevEl.style.top = navigationTop + 'px';
      // 初回のスライド生成時にスライドのチェックを行う
      checkSlide(reviewSwiper.swiper);
    });

    function checkSlide(swiper) {
      const activeIndex = swiper.activeIndex; // アクティブなスライドのインデックス
      const lastIndex = swiper.slides.length - 1; // スライドの最後のインデックス
      const slidesPerView = swiper.params.slidesPerView; // 1回に表示するスライド数

      // スライド数とスライド表示数の状態を確認して、前へ、次へボタンの表示状態を変更
      if (swiper.slides.length <= slidesPerView) {
        // スライド数がスライド表示数以下の場合、前へ、次へボタンを非表示にする
        prevBtn.classList.add('swiper-button-disabled');
        nextBtn.classList.add('swiper-button-disabled');
      } else {
        // スライド数がスライド表示数より多い場合、前へ、次へボタンの表示状態を変更
        if (activeIndex === 0) {
          // 最初のスライドの場合、前へボタンを非表示にする
          prevBtn.classList.add('swiper-button-disabled');
          nextBtn.classList.remove('swiper-button-disabled');
        } else if (
          (activeIndex === lastIndex && slidesPerView === 1) ||
          (activeIndex === lastIndex - 1 && slidesPerView === 2)
        ) {
          // 最後のスライドの場合、次へボタンを非表示にする（2列表示の場合は最後から2番目で判定）
          prevBtn.classList.remove('swiper-button-disabled');
          nextBtn.classList.add('swiper-button-disabled');
        } else {
          // 途中のスライドの場合、前へ、次へボタンを表示する
          prevBtn.classList.remove('swiper-button-disabled');
          nextBtn.classList.remove('swiper-button-disabled');
        }
      }
    }

    // 初期化完了クラスをセットして、再度初期化が行われないようにする
    reviewSwiper.classList.add('initialized');
  }
}
