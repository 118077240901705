import { Component, OnDestroy, OnInit, Renderer2, ViewChild, ViewChildren, ElementRef, Input } from '@angular/core';
import { PagedataService } from '../services/pagedata.service';
import { HomePageData } from '../interfaces/homePageData.interface';
import { SalonData } from '../interfaces/salonData.interface';
import { LayoutData } from '../interfaces/layoutData.interface';
import { initHomePageData, initLayoutData, initSalonData } from '../initData/initData';
import { MetaDataController } from '../services/metaDataController';
import { NavigationController } from '../services/navigationController';
import { RequestParamsService } from '../services/requestParams.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { HomeToHeaderService } from '../services/homeToHeader';
import { HomeToMobilefooterService } from '../services/homeToMobilefooter';
import { NoticeBlurActive } from '../services/noticeBlurActive';
import { NoticeBlurActiveForModal } from '../services/noticeBlurActiveForModal';
import { WindowService } from '../services/windowService';
import { ShopDetailModalService } from '../services/shopDetailModal.service';
import { NoticeBlurActiveFromHeader } from '../services/noticeBlurActiveFromHeader';
import { NoticeBlurActiveFromHeaderForModal } from '../services/noticeBlurActiveFromHeaderForModal';
import { Subscription } from 'rxjs';
import { IonContent } from '@ionic/angular';
import { EmergencyNewsPage } from '../emergencynews/emergencynews.page';
import { MobilefooterbuttonsComponent } from '../mobilefooterbuttons/mobilefooterbuttons.component';
import { Router } from '@angular/router';
import { BlockService } from '../services/block.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss']
})
export class HomePage implements OnInit, OnDestroy {
  @Input() shopName: string;
  @Input() isShopDetailNav: boolean = false;
  @Input() requestParams: any;

  @ViewChild(IonContent, { static: false }) content: IonContent;
  @ViewChild('galleryModal', { static: false }) galleryModal;
  loadingImage = './assets/images/icons/loading-dot.png';
  showInstallMessage: boolean = false;
  showInstallMessageforiPad: boolean = false;

  aboutImageHtml: SafeHtml;
  heroImagePcHtml: SafeHtml;
  menuImageHtml: SafeHtml;
  staffImageHtml: SafeHtml;
  shopImageHtml: SafeHtml;
  campaignImageHtml: SafeHtml;

  noLogoHeroStyle = {
    noLogo__middle: false,
    noLogo__middleRight: false,
    noLogo__middleLeft: false,
    noLogo__bottom: false,
    noLogo__bottomRight: false,
    noLogo__bottomLeft: false
  };
  noHeaderBg = {
    nonMarginTop: false
  };
  effect_0 = false;
  effect_0_3 = false;
  twoColumns = {
    fullWidth: false,
    halfWidth: true
  };
  animationFlag: boolean = true;

  pageData: HomePageData = initHomePageData;

  shopDetailPageData: HomePageData = initHomePageData;
  public blockData: any = [];

  name: string;

  layoutData: LayoutData = initLayoutData;

  isDisplayStaff: boolean = true;
  isFirstAccess: boolean = true; // アクセス判定フラグ（falseの場合、ホーム➔別ページ➔ホームと2回目に表示されたことを判定）

  public salonData: SalonData = initSalonData;
  public isShowPrivateMessage: boolean = false;
  public privateMsgHtml: SafeHtml;
  // モーダルを開くでtrue、閉じるとfalse
  openModalFlg: boolean = false;
  // 他コンポーネントのぼかしクラスの制御
  blurActiveFlg: boolean = false;
  private subscription: Subscription;
  blurActiveFlgFromHeader: boolean = false;
  blurActiveModalFlgFromHeader: boolean = false;
  public paramKey: string = null;
  public isShowHomeFooter: boolean = false;
  public blockAreaListForAddBlock: any = {};
  @ViewChildren('appBlock') appBlockElement;
  @ViewChildren('videoIframeContent') videoIframeContentElement;
  @ViewChild('appEmergencynews', { static: false }) appEmergencynewsElement;

  isDisplayHeroImg = true; // メインビジュアルに画像も動画も設定されていない、または非表示設定の場合false。アニメーション制御
  isDisplayAboutSec = true; // aboutセクションに何も登録されていない、または非表示設定の場合false。アニメーション制御

  @ViewChild(EmergencyNewsPage, { static: false })
  private emergencyNews: EmergencyNewsPage;
  @ViewChild(MobilefooterbuttonsComponent, { static: false })
  private mfbc: MobilefooterbuttonsComponent;

  params: any;

  constructor(
    public pds: PagedataService,
    private metaDataController: MetaDataController,
    private sanitizer: DomSanitizer,
    private navigationController: NavigationController,
    private requestParamsService: RequestParamsService,
    private homeToHeaderService: HomeToHeaderService,
    private homeToMobilefooterService: HomeToMobilefooterService,
    private noticeBlurActive: NoticeBlurActive,
    private noticeBlurActiveForModal: NoticeBlurActiveForModal,
    public windowService: WindowService,
    private shopDetailModalService: ShopDetailModalService,
    private renderer2: Renderer2,
    private noticeBlurActiveFromHeader: NoticeBlurActiveFromHeader,
    private noticeBlurActiveFromHeaderForModal: NoticeBlurActiveFromHeaderForModal,
    private elem: ElementRef,
    public router: Router,
    private blockService: BlockService
  ) {}

  ngOnInit() {
    this.load();

    // イベント登録
    // サービスで共有しているデータが更新されたら発火されるイベントをキャッチする
    this.subscription = this.noticeBlurActiveFromHeader.sharedDataSource$.subscribe((msg) => {
      this.blurActiveFlgFromHeader = msg;
    });
    this.subscription = this.noticeBlurActiveFromHeaderForModal.sharedDataSource$.subscribe((msg) => {
      this.blurActiveModalFlgFromHeader = msg;
    });
  }

  private async load() {
    const isInStandaloneMode = this.pds.getIsStandAloneMode();
    const salonData = await this.pds.getSalonData(this.shopName);

    // iOSのA2HS
    if (this.pds.getIsSafari() && this.pds.getIsIos() && !isInStandaloneMode) {
      switch (salonData['appEnabled']) {
        case 3:
        case 4:
        case 5: // 本部の場合、アプリ化メッセージを表示
        case 6: // 店舗詳細ページの場合、もアプリ化メッセージを表示
          if (this.pds.getIsipad()) {
            this.showInstallMessageforiPad = true;
          } else {
            this.showInstallMessage = true;
          }
          break;
      }
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ionViewWillEnter() {
    // 戻るボタン・スワイプバックでこのページについた場合はスクロール位置を修正（データが残っているのでデータの読み直しはしない）
    // 進むボタン時はコンポーネント初期化されてデータがないので、elseへ
    if (!this.navigationController.getClickedRouterLink() && this.pageData.pageTitle != null) {
      // 戻るボタンなどで戻ってきた際に、緊急ニュースが表示されている場合は再表示する
      if (this.appEmergencynewsElement.elem.nativeElement.children.length > 0) {
        // 緊急ニュースを再表示する
        this.emergencyNews.reDisplay();
      }
    } else {
      if (this.openModalFlg) {
        this.galleryModal.closeModal('homeModal__content');
      }
      // 初回アクセス時
      if (this.isFirstAccess) {
        const promise = this.loadPage();
        promise
          .then((result) => (this.content != undefined ? this.content.scrollToTop() : {}))
          .catch((e) => console.error(e))
          .finally(() => (this.content != undefined ? this.content.scrollToTop() : {}));
        this.pds.preload();
        // 初回アクセスがホームの場合、別ページ移動後にホームに戻ってきた際はfalseの処理を行うようにフラグを更新する
        this.isFirstAccess = false;
      } else {
        // 初回アクセスでない場合（ホーム➔別ページ➔ホーム　とか）
        // スクロールをトップに戻す
        this.content.scrollToTop();
        // 緊急ニュースを再表示する
        this.emergencyNews.reDisplay();
        // タイトルを更新する
        this.updateTitle();
      }
    }
    this.navigationController.setClickedRouterLink(false);
    this.navigationController.refreshUrl();
  }

  ionViewDidEnter() {
    if (this.openModalFlg) {
      this.addBlurActive();
    }
  }

  ionViewDidLeave() {
    // iframe要素を全て再セットする（停止処理）
    this.stopVideo();
  }

  ionViewWillLeave() {
    this.removeBlurActive();
  }

  private async loadPage() {
    this.params = this.requestParamsService.getRequestParams();
    this.paramKey = this.params.key;
    this.salonData = await this.pds.getSalonData(this.shopName);

    // この条件の場合は非公開状態
    if (
      (this.params.isPreview && this.params.isPrivate) ||
      ((!this.salonData.isPublic || this.salonData.publishedDate == null) && !this.pds.getPreview())
    ) {
      this.isShowPrivateMessage = true;
    }

    if (!this.isShowPrivateMessage && this.showInstallMessageforiPad) {
      this.homeToHeaderService.onNotifySharedDataChanged(this.showInstallMessageforiPad);
    }
    if (!this.isShowPrivateMessage && this.showInstallMessage) {
      this.homeToMobilefooterService.onNotifySharedDataChanged(this.showInstallMessage);
    }

    this.layoutData = await this.pds.getLayoutData(this.shopName);
    this.pageData = await this.pds.getHomePageData(this.shopName);
    this.blockData = this.pageData.blockData;
    this.blockService.remakeBlockData(this.blockData);
    this.blockService.updateAltSalonName(this.pageData.altSalonName);
    // iframeデータからHTML作成
    for (let i = 0; i < this.pageData.menus.length; i++) {
      if (this.pageData.menus[i].detail != null) {
        if (this.pageData.menus[i].detail.homeImageDisplayType == 4) {
          this.pageData.menus[i].detail.homeImageHtml = this.sanitizer.bypassSecurityTrustHtml(
            this.pageData.menus[i].detail.homeImageIframe
          );
        }
      }
    }
    if (this.pageData.aboutImageDisplayType == 4) {
      this.aboutImageHtml = this.sanitizer.bypassSecurityTrustHtml(this.pageData.aboutImageIframe);
    }
    if (this.pageData.heroImagePcDisplayType == 4) {
      this.heroImagePcHtml = this.sanitizer.bypassSecurityTrustHtml(this.pageData.heroImagePcIframe);
    }
    if (this.pageData.menuImageDisplayType == 4) {
      this.menuImageHtml = this.sanitizer.bypassSecurityTrustHtml(this.pageData.menuImageIframe);
    }
    if (this.pageData.staffImageDisplayType == 4) {
      this.staffImageHtml = this.sanitizer.bypassSecurityTrustHtml(this.pageData.staffImageIframe);
    }
    if (this.pageData.shopImageDisplayType == 4) {
      this.shopImageHtml = this.sanitizer.bypassSecurityTrustHtml(this.pageData.shopImageIframe);
    }
    if (this.pageData.campaignImageDisplayType == 4) {
      this.campaignImageHtml = this.sanitizer.bypassSecurityTrustHtml(this.pageData.campaignImageIframe);
    }

    if (this.params.shopDetail != null) {
      this.shopDetailPageData = await this.pds.getHomePageData(this.params.shopDetail);
    }

    if (this.layoutData.headerBg == 'noHeightTranslucent' || this.layoutData.headerBg == 'noHeightTransparent') {
      this.noHeaderBg.nonMarginTop = true;
    }

    const noLogoStyle = this.pageData.noLogoHeroStyle;
    this.noLogoHeroStyle[noLogoStyle] = true;
    if (!this.pageData.twoColumns) {
      this.twoColumns.fullWidth = true;
      this.twoColumns.halfWidth = false;
    }
    if (this.pageData.scrollEffect == 'effect_3') {
      this.effect_0_3 = true;
      this.effect_0 = false;
    } else if (this.pageData.scrollEffect == 'effect_4') {
      this.effect_0 = false;
      this.effect_0_3 = false;
    } else {
      this.effect_0 = true;
      this.effect_0_3 = false;
    }

    // タイトルを更新
    this.updateTitle();

    // 担当スタッフの表示判定
    this.isDisplayStaff = this.pageData.isDisplayStaff;

    // const aboutBlock = this.pageData.blockData.filter((b) => b.position.indexOf('top__about__') == 0);
    const existAboutBlock =
      (this.pageData.blockData['top__about__titleBlock'] !== undefined &&
        this.pageData.blockData['top__about__titleBlock'].length > 0) ||
      (this.pageData.blockData['top__about__descBlock'] !== undefined &&
        this.pageData.blockData['top__about__descBlock'].length > 0) ||
      (this.pageData.blockData['top__about__visualBlock'] !== undefined &&
        this.pageData.blockData['top__about__visualBlock'].length > 0);

    if ((!this.pageData.heroImagePc && !this.pageData.heroImagePcIframe) || !this.pageData.isDisplayHeroImagePc) {
      this.isDisplayHeroImg = false;
    }
    if (
      (!this.pageData.aboutTitle &&
        !this.pageData.aboutConcept &&
        ((!this.pageData.aboutImage && !this.pageData.aboutImageIframe) || !this.pageData.isDisplayAboutImage) &&
        !existAboutBlock) ||
      !this.pageData.isDisplayAbout
    ) {
      this.isDisplayAboutSec = false;
    }
  }

  updateTitle() {
    if (this.params.shopDetail == null) {
      this.metaDataController.updateTitleDescription(this.pageData['pageTitle'], this.pageData['pageDescription']);
      this.metaDataController.updateOGTitleDescription(
        this.pageData['pageTitle'],
        this.pageData['pageDescription'],
        this.navigationController.getCurrentUrl()
      );
    } else {
      // 店舗詳細モーダル内のページ指定にて、page=homeの指定もしくはページ指定がない場合に実行
      if (this.params.page === 'home' || this.params.page === null) {
        this.metaDataController.updateTitleDescription(
          this.shopDetailPageData['pageTitle'],
          this.shopDetailPageData['pageDescription']
        );
        this.metaDataController.updateOGTitleDescription(
          this.shopDetailPageData['pageTitle'],
          this.shopDetailPageData['pageDescription'],
          this.navigationController.getCurrentUrl()
        );
      }
    }
  }

  addReveal(scrollSec) {
    const reveal = this.renderer2.createElement('div');
    this.renderer2.addClass(reveal, 'reveal');
    const revealPic = this.renderer2.createElement('div');
    this.renderer2.addClass(revealPic, 'reveal_pic');
    if (this.isShopDetailNav) {
      this.renderer2.addClass(reveal, '__modalStyle__');
      this.renderer2.addClass(revealPic, '__modalStyle__');
    }

    if (!scrollSec.lastElementChild) {
      if (scrollSec.classList.contains('pic')) {
        scrollSec.appendChild(revealPic);
      } else {
        scrollSec.appendChild(reveal);
      }
    } else if (
      !scrollSec.lastElementChild.classList.contains('reveal') &&
      !scrollSec.lastElementChild.classList.contains('reveal_pic')
    ) {
      if (scrollSec.classList.contains('pic')) {
        scrollSec.appendChild(revealPic);
      } else {
        scrollSec.appendChild(reveal);
      }
    }
  }

  scrollEffect(event): any {
    this.isShowHomeFooter = true;
    this.content.getScrollElement().then((data) => {
      const scrollPos = data.scrollTop;
      const sections = this.elem.nativeElement.querySelectorAll('.scroll_section');

      if (this.pageData.scrollEffect == 'effect_3') {
        sections.forEach((section) => {
          if (scrollPos > section.offsetTop - 600) {
            let secContainer = section.children[0];
            if (secContainer != null) {
              let elCount = secContainer.childElementCount;
              let scrollSec = secContainer.children;
              for (let i = 0; i < elCount; i++) {
                this.addReveal(scrollSec[i]);
                scrollSec[i].classList.add(this.pageData.scrollEffect);
              }
            }
          }
        });
      } else {
        //effect_1 と effect_2
        const sections = this.elem.nativeElement.querySelectorAll('.scroll_section');
        for (let i = 0; i < sections.length; i++) {
          if (scrollPos > sections[i].offsetTop - 500) {
            sections[i].classList.add(this.pageData.scrollEffect);
          }
        }
      }
    });
  }

  addBlurActive() {
    // ヘッダーにぼかしクラスをつける
    if (this.isShopDetailNav) {
      this.noticeBlurActiveForModal.onNotifySharedDataChanged(true);
    } else {
      this.noticeBlurActive.onNotifySharedDataChanged(true);
    }
    this.blurActiveFlg = true;
  }

  removeBlurActive() {
    // ヘッダーのぼかしクラスを外す
    if (this.isShopDetailNav) {
      this.noticeBlurActiveForModal.onNotifySharedDataChanged(false);
    } else {
      this.noticeBlurActive.onNotifySharedDataChanged(false);
    }
    this.blurActiveFlg = false;
  }

  modalcontrol(flg: boolean) {
    if (flg) {
      this.openModalFlg = true;
      this.addBlurActive();
    } else {
      this.openModalFlg = false;
      this.removeBlurActive();
    }
  }

  clickLink(link, requestParams = {}) {
    if (this.isShopDetailNav) {
      // 店舗詳細モーダルで開いた場合
      this.shopDetailModalService.pushPage(this.shopName, link, requestParams);
    } else {
      // pageNameはモーダルでは使用しないので、パラメータを削除する
      delete requestParams['pageName'];
      // モーダルを閉じた状態でクリックした場合、通常のページ遷移を行う
      this.router.navigate(['/' + link], { queryParams: requestParams });
      this.navigationController.setClickedRouterLink(true);
    }
  }

  // 動画を止める
  stopVideo() {
    // ページ内動画要素を取得する
    let videoIframeContentList = this.videoIframeContentElement.toArray();
    // 取得した結果、動画要素が存在した場合
    if (videoIframeContentList.length > 0) {
      // 各動画要素のinnerHTML(iframe部分)を再セットする
      for (let videoIframeContent of videoIframeContentList) {
        videoIframeContent.nativeElement.innerHTML = videoIframeContent.nativeElement.innerHTML;
      }
    }
    // 動画ブロックをリセットする
    let appBlockList = this.appBlockElement.toArray();
    for (let appBlock of appBlockList) {
      appBlock.resetVideoBlock();
    }
    // 緊急ニュース内の動画ブロックも停止処理を行う
    this.appEmergencynewsElement.stopVideo();
  }
}
