import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RequestParamsService {
  public isCompanyForLocal = false;

  constructor() {
    if (environment.local) {
      const params = new URLSearchParams(window.location.search.slice(1));
      const local = params.get('company');
      if (local !== null) {
        this.isCompanyForLocal = true;
      }
    }
  }

  public getRequestParams(urlParams = null) {
    let url = window.location;
    let baseUrl = window.location;
    if (urlParams) {
      url = urlParams;
    }
    const params = new URLSearchParams(url.search.slice(1));
    const baseParams = new URLSearchParams(baseUrl.search.slice(1));
    const requestParams = new RequestParams();

    const hostname = url.hostname.replace(/^www\./, '');
    let salonName = '';
    let isCompany = false;
    if (hostname.indexOf('.1cs') > 0 || hostname.indexOf('.localhost') > 0) {
      salonName = hostname.substring(0, hostname.indexOf('.'));
      if (hostname.split('.')[1] === 'pwah') {
        isCompany = true;
      }
    }
    requestParams.salonOrDomainName = salonName !== '' ? salonName : hostname;

    // ローカルホスト用のパラメータ
    if (hostname.indexOf('localhost') >= 0 || hostname.indexOf('192.168') >= 0) {
      const local = params.get('company');
      if (local !== null) {
        isCompany = true;
      }
      const shopName = params.get('s');
      if (shopName !== null) {
        requestParams.salonOrDomainName = shopName;
      }
    }

    const isPreview = params.get('p');
    requestParams.isPreview = isPreview !== null;

    const newsId = params.get('news_id');
    if (newsId !== null) {
      requestParams.newsId = parseInt(newsId);
    }

    const campaignId = params.get('campaign_id');
    if (campaignId !== null) {
      requestParams.campaignId = parseInt(campaignId);
    }

    const staffId = params.get('staff_id');
    if (staffId !== null) {
      requestParams.staffId = parseInt(staffId);
    }

    const isPrivate = params.get('private');
    requestParams.isPrivate = isPrivate !== null;

    const key = params.get('key');
    requestParams.key = key;

    const pageName = params.get('pageName') !== null ? params.get('pageName') : url.pathname;
    requestParams.pageName = pageName;

    const shopDetail = params.get('shopDetail');
    requestParams.shopDetail = shopDetail;
    requestParams.isCompanyForNonCustomDomain = isCompany;

    const action = params.get('action');
    requestParams.action = action;

    const page = params.get('page');
    requestParams.page = page;

    let isExternalPage = false;
    if (url.host === baseUrl.host) {
      // ページURLと外部リンクURLが同一サイト内の場合
      // 店舗詳細名がセットされている場合はサイト外、されていない場合はサイト内と判断
      isExternalPage = shopDetail !== null;
    } else {
      // ページURLと外部リンクURLが別サイトの場合、外部のページと判断
      isExternalPage = true;
    }
    requestParams.isExternalPage = isExternalPage;

    // ここでは独自ドメイン以外のURLのみ判断。
    // 独自ドメインの判断が必要な場合はpagedata.service.tsのownDomainListを使って別途判断する
    let isOutExternalPage = !(hostname.split('.')[1] === 'pwa' || hostname.split('.')[1] === 'pwah');
    if (hostname.indexOf('localhost') >= 0) {
      isOutExternalPage = false;
    }
    requestParams.isOutExternalPage = isOutExternalPage;

    // 外部のサイトの場合パラメータを整理
    params.forEach(function (value, key) {
      requestParams.params[key] = value;
    });

    // ローカルでcompanyを指定しているページの場合、次に表示するページのパラメータもcompany=trueを指定する
    if (baseUrl.hostname.indexOf('localhost') >= 0 && baseParams.get('company')) {
      requestParams.params['company'] = true;
    }

    const brandId = params.get('brand_id');
    if (brandId !== null) {
      requestParams.brandId = parseInt(brandId);
    }

    const viewMode = params.get('view_mode');
    requestParams.viewMode = viewMode;

    const isReload = params.get('reload');
    requestParams.isReload = isReload !== null;

    return requestParams;
  }
}

export class RequestParams {
  isPreview: boolean = false;
  salonOrDomainName: string = null;
  newsId: number = null;
  campaignId: number = null;
  staffId: number = null;
  isPrivate: boolean = false;
  key: string = null;
  pageName: string = null;
  shopDetail: string = null;
  action: string = null;
  page: string = null;
  isCompanyForNonCustomDomain: boolean = false;
  isExternalPage: boolean = false;
  isOutExternalPage: boolean = false;
  isReload: boolean = false;
  params: any = {};
  brandId: number = null;
  viewMode: string = null;
}
