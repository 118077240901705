import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ViewChild,
  ComponentFactoryResolver,
  ViewContainerRef,
  HostListener,
  ChangeDetectorRef
} from '@angular/core';
import { PagedataService } from '../services/pagedata.service';
import { IonNav, ModalController } from '@ionic/angular';
import { OpenPageInfo } from '../interfaces/openPageInfo.interface';
import { NavigationController } from '../services/navigationController';
import { NoticeBlurActiveForModal } from '../services/noticeBlurActiveForModal';
import { NoticeBlurActiveFromHeaderForModal } from '../services/noticeBlurActiveFromHeaderForModal';
import { Subscription } from 'rxjs';
import { LayoutData } from '../interfaces/layoutData.interface';
import { SalonData } from '../interfaces/salonData.interface';
import { DomainRelatedAccountData } from '../interfaces/domainRelatedAccountData.interface';
import { initLayoutData, initSalonData, initSalonPageData } from '../initData/initData';
import { UpdateGoogleAnalyticsService } from '../services/updateGoogleAnalyticsService';
import { MetaDataController } from '../services/metaDataController';
import { RequestParamsService } from '../services/requestParams.service';

import { AccountService } from '../services/account.service';
import { ShopDetailModalService } from '../services/shopDetailModal.service';
import { WindowService } from '../services/windowService';
import { NavigateService } from '../services/navigate.service';
import { SalonPageData } from '../interfaces/salonPageData.interface';
import { HomePageData } from '../interfaces/homePageData.interface';

@Component({
  selector: 'app-shopdetailmodal',
  templateUrl: '../shopdetailmodal/shopdetailmodal.component.html',
  styleUrls: ['../shopdetailmodal/shopdetailmodal.component.scss'],
  host: {
    class: 'modalContent'
  }
})
export class ShopdetailmodalComponent implements OnInit, OnDestroy {
  // 店舗詳細モーダル起動時の店舗名、ページパラメータ取得
  @Input() shopName: string;
  @Input() pageName: string;
  @Input() requestParams: string;
  @Input() modal: any;
  @ViewChild('pageContent', { static: false }) pageContentElement;
  @ViewChild('shopDetailNav', { static: false }) shopDetailNav: IonNav;
  @ViewChild('mobileFooter', { static: false }) mobileFooter;
  @ViewChild('appHeader', { static: false }) appHeader;
  isCanBack: boolean = false;
  isShopDetailNav: boolean = true;
  mobileFooterPageName: string;
  private subscription: Subscription;
  blurActiveModalFlg: boolean = false;
  blurActiveModalFlgFromHeader: boolean = false;
  browserBackForModalCallbackEvent;
  isNavDidChange = false;
  shopDetailModalStyles: any;

  public layoutData: LayoutData = initLayoutData;
  public salonData: SalonData = initSalonData;
  public isShowPrivateMessage: boolean = false;
  private salonAccountName: string = null;
  salonPageData: SalonPageData = initSalonPageData;
  public ogData: HomePageData = null;

  constructor(
    public pds: PagedataService,
    private noticeBlurActiveForModal: NoticeBlurActiveForModal,
    private navigationController: NavigationController,
    private modalController: ModalController,
    private noticeBlurActiveFromHeaderForModal: NoticeBlurActiveFromHeaderForModal,
    private viewContainerRef: ViewContainerRef,
    private resolver: ComponentFactoryResolver,
    private updateGoogleAnalyticsService: UpdateGoogleAnalyticsService,
    private requestParamsService: RequestParamsService,
    private changeDetectionRef: ChangeDetectorRef,
    private metaDataController: MetaDataController,
    public accountService: AccountService,
    private shopDetailModalService: ShopDetailModalService,
    public windowService: WindowService,
    public navigateService: NavigateService
  ) {
    this.browserBackForModalCallbackEvent = this.browserBackForModal.bind(this);
  }

  @HostListener('window:click', ['$event'])
  async onClickWindow(e) {
    let clickElem = e.target as Element;
    // クリックされた箇所がヘッダーかどうか
    const isAppHeader = clickElem.closest('app-header') !== null;
    if (!isAppHeader) {
      // ヘッダー以外のクリックなら吹き出しを閉じる
      this.appHeader.closeNavList(false);
    }
    // 内部リンクと判断されるリスト
    let internalUrlList = [];
    for (let key in this.layoutData['pathNameMap']) {
      let url = '';
      if (key.match('gallery')) {
        let urlParams = key.split('_');
        url = urlParams[0];
        if (urlParams[1]) {
          url += '?brand_id=' + urlParams[1];
        }
      } else {
        url = key;
      }
      internalUrlList.push(url);
    }
    if (
      clickElem.tagName === 'A' &&
      !(clickElem.classList.contains('block__button') || clickElem.classList.contains('block__shopLink'))
    ) {
      // クリックした要素がaタグで、ボタン・店舗リンクのブロックでない場合
      let href = clickElem.getAttribute('href');
      let isOpenNewTab = clickElem.getAttribute('target') === '_blank';
      let hrefVals = [];
      if (href !== null) {
        if (href.indexOf('?') !== -1) {
          // テキストエディターから追加したリンク用（内部リンク）
          // 「/news?s=XXXX」のようにsパラメータが残っている
          hrefVals = href.split('?');
        } else {
          // ブロック追加のボタン（内部リンク）用
          // 「/news」のようにsパラメータなし
          hrefVals[0] = href;
        }
      } else {
        // hrefが設定されていない場合、処理を中断（以降の処理も行わない）
        e.preventDefault();
        return false;
      }

      // javascript:void(0);以外のリンクを処理
      if (hrefVals[0].indexOf('javascript:void(0);') === -1) {
        // メールアドレス、電話番号じゃない場合に処理を続行
        if (hrefVals[0].indexOf('mailto:') === -1 && hrefVals[0].indexOf('tel:') === -1) {
          // 処理を中断
          e.preventDefault();
          var $companyContents = clickElem.closest('.companyContents');
          if ($companyContents) {
            // companyContentsクラスを含む要素内のリンクを処理する
            let hrefVals2 = [];
            hrefVals2 = href.split('/');
            if (hrefVals2[0] === '') {
              // 「/」で区切った際の先頭が空文字なので内部リンクと判断し、すべて外部リンクとして処理する
              await this.navigateService.navigateUrlForCompanyContents(
                href,
                isOpenNewTab,
                $companyContents.attributes['data-salon_id'].value
              );
            } else {
              // 外部リンク遷移を行う
              await this.navigateService.navigateExternalUrl(href, isOpenNewTab);
            }
          } else {
            if (internalUrlList.includes(href)) {
              // 内部リンク遷移を行う
              this.navigateService.navigateInternalUrl(href, isOpenNewTab);
            } else {
              // 外部リンク遷移を行う
              await this.navigateService.navigateExternalUrl(href, isOpenNewTab);
            }
          }
        }
      }
    }
  }

  ngOnInit() {
    this.load();
    this.subscription = this.noticeBlurActiveForModal.sharedDataSource$.subscribe((msg) => {
      this.blurActiveModalFlg = msg;
    });
    // イベント登録
    // サービスで共有しているデータが更新されたら発火されるイベントをキャッチする
    this.subscription = this.noticeBlurActiveFromHeaderForModal.sharedDataSource$.subscribe((msg) => {
      this.blurActiveModalFlgFromHeader = msg;
    });

    // ブラウザバックイベントとして、bind(this)したブラウザバックコールバックイベントをセット
    window.addEventListener('popstate', this.browserBackForModalCallbackEvent);
    this.changeDetectionRef.detectChanges();
    // 店舗詳細モーダル内の要素をサービスにセット
    this.shopDetailModalService.shopDetailNav = this.shopDetailNav;
    this.shopDetailModalService.mobileFooter = this.mobileFooter;
    this.shopDetailModalService.appHeader = this.appHeader;

    this.pds.updatePrerenderReady(true);
  }

  ionViewWillEnter() {
    // 店舗詳細を店舗一覧（カードモーダル）以外から開いた場合
    if (!this.modal.presentingElement.classList.contains('card-modal')) {
      // cardモーダル表示用のクラスをつける
      this.navigationController.isOpenCardModal = true;
    }
    this.setTheme(this.shopName);
    this.shopDetailModalService.pushPage(this.shopName, this.pageName, this.requestParams);
  }

  ionViewDidEnter() {}

  ionViewWillLeave() {
    // 店舗詳細を店舗一覧（カードモーダル）以外から開いた場合
    if (!this.modal.presentingElement.classList.contains('card-modal')) {
      // cardモーダル表示用のクラスを外す
      this.navigationController.isOpenCardModal = false;
    }
    this.resetTheme();
  }

  ionViewDidLeave() {}

  ngOnDestroy() {
    this.subscription.unsubscribe();
    // ブラウザバックイベントのブラウザバックコールバックイベントを外す
    window.removeEventListener('popstate', this.browserBackForModalCallbackEvent);
  }

  private async load() {
    this.layoutData = await this.pds.getLayoutData(this.shopName);
    this.salonData = await this.pds.getSalonData(this.shopName);
    this.salonPageData = await this.pds.getSalonPageData(this.shopName);
    this.ogData = await this.pds.getHomeDataForOG(this.shopName);
    const accountData: DomainRelatedAccountData = this.accountService.getSalonNameAndIsCompany();
    this.salonAccountName = !!accountData.accountName ? accountData.accountName : null;
    // この条件の場合は非公開状態
    if ((!this.salonData.isPublic || this.salonData.publishedDate === null) && !this.pds.getPreview()) {
      this.isShowPrivateMessage = true;
    }
    // OGP画像、構造化データの更新
    this.metaDataController.updateOGImage(this.ogData);
    this.metaDataController.updateBeautySalonStructuredDataForShopDetail(
      this.salonPageData['name'],
      this.salonPageData['address'],
      this.salonPageData['tel'],
      this.shopName
    );
  }

  // モーダル表示時のブラウザバック処理
  browserBackForModal() {
    if (this.isCanBack) {
      // モーダルの戻る処理を実行する
      this.back();
    } else {
      // モーダルを閉じる
      this.close();
    }
  }

  // 戻るボタン
  async back() {
    let self = this;
    this.shopDetailNav.pop(null, function () {
      self.metaDataController.refreshTitleDescription();
    });
    let previous = this.shopDetailNav.getPrevious();
    previous.then((data) => this.mobileFooter.refreshFooterBtn(data.params.pageName));
    this.isCanBack = await this.shopDetailNav.canGoBack();
  }

  // 閉じるボタン
  close() {
    this.shopDetailModalService.closeShopDetailModal();
  }

  private async setTheme(shopName) {
    let themeData = await this.pds.getThemeDataForEnterpriseShop(shopName);
    this.shopDetailModalStyles = this.pds.setThemeForShopDetailModal(themeData);
  }

  private resetTheme() {
    this.pds.resetThemeForShopDetailModal();
  }

  // スワイプバック時に戻るボタンの表示・非表示を更新する
  // （スワイプイベントを取得できないのでionNavDidChangeでキックする）
  async checkCanGoBack() {
    this.isCanBack = await this.shopDetailNav.canGoBack();
  }

  // モーダル内をスクロールした際に、モーダル自体のスクロールを止める
  stopDragging(event: TouchEvent): void {
    event.stopPropagation();
  }
}
