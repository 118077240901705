import { Component, OnDestroy, OnInit, ViewChild, ViewChildren, ElementRef, Input, QueryList } from '@angular/core';

import { PagedataService } from '../services/pagedata.service';
import { CampaignPageData } from '../interfaces/campaignPageData.interface';
import { SalonData } from '../interfaces/salonData.interface';
import { initCampaignPageData, initSalonData } from '../initData/initData';
import { NavigationController } from '../services/navigationController';
import { Router } from '@angular/router';
import { RequestParamsService } from '../services/requestParams.service';

import { MetaDataController } from '../services/metaDataController';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NoticeBlurActiveFromHeader } from '../services/noticeBlurActiveFromHeader';
import { NoticeBlurActiveFromHeaderForModal } from '../services/noticeBlurActiveFromHeaderForModal';
import { Subscription } from 'rxjs';
import { IonContent } from '@ionic/angular';

import { MobilefooterbuttonsComponent } from '../mobilefooterbuttons/mobilefooterbuttons.component';
import { BlockComponent } from '../block/block.component';
import { WindowService } from '../services/windowService';
import { ShopDetailModalService } from '../services/shopDetailModal.service';
import { BlockService } from '../services/block.service';

@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.page.html',
  styleUrls: ['./campaign.page.scss']
})
export class CampaignPage implements OnInit, OnDestroy {
  @Input() shopName: string;
  @Input() isShopDetailNav: boolean = false;
  @Input() requestParams: any;

  @ViewChild(IonContent, { static: false }) content: IonContent;
  loadingImage = './assets/images/icons/loading-dot.png';
  public pageData: CampaignPageData = initCampaignPageData;
  public blockData: any = [];
  public salonData: SalonData = initSalonData;
  public isShowPrivateMessage: boolean = false;
  public privateMsgHtml: SafeHtml;
  private subscription: Subscription;
  blurActiveFlgFromHeader: boolean = false;
  blurActiveModalFlgFromHeader: boolean = false;
  private foundEle: boolean = false;
  pageDataForPub: CampaignPageData; // プレビュー時使用
  isDisplayPrevPage: boolean = true;
  isDisplayPubPage: boolean = true;
  @ViewChild(MobilefooterbuttonsComponent, { static: false })
  private mfbc: MobilefooterbuttonsComponent;
  isCheckShop: boolean = true;

  public blockAreaListForAddBlock: any = {};
  @ViewChildren('appBlock') appBlockElement: QueryList<BlockComponent>;
  @ViewChildren('videoIframeContent') videoIframeContentElement: QueryList<ElementRef>;
  @ViewChildren('campaign') campaignElements: QueryList<ElementRef>;
  @ViewChild('shopCampaignGroup') shopCampaignGroup: ElementRef;
  public pageTitle: string;

  constructor(
    public pds: PagedataService,
    private metaDataController: MetaDataController,
    private sanitizer: DomSanitizer,
    private navigationController: NavigationController,
    private noticeBlurActiveFromHeader: NoticeBlurActiveFromHeader,
    private noticeBlurActiveFromHeaderForModal: NoticeBlurActiveFromHeaderForModal,
    private elem: ElementRef,
    public router: Router,
    public windowService: WindowService,
    private requestParamsService: RequestParamsService,
    private shopDetailModalService: ShopDetailModalService,
    private blockService: BlockService
  ) {}

  ngOnInit() {
    // イベント登録
    // サービスで共有しているデータが更新されたら発火されるイベントをキャッチする
    this.subscription = this.noticeBlurActiveFromHeader.sharedDataSource$.subscribe((msg) => {
      this.blurActiveFlgFromHeader = msg;
    });
    this.subscription = this.noticeBlurActiveFromHeaderForModal.sharedDataSource$.subscribe((msg) => {
      this.blurActiveModalFlgFromHeader = msg;
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ionViewWillEnter() {
    // 戻るボタン・スワイプバックでこのページについた場合はスクロール位置を修正（データが残っているのでデータの読み直しはしない）
    // 動画の読み直しのためsetBlockを実行する
    // 進むボタン時はコンポーネント初期化されてデータがないので、elseへ
    if (!this.navigationController.getClickedRouterLink() && this.pageData.pageTitle != null) {
    } else {
      const promise = this.loadData();
      promise
        .then((result) => (this.content != undefined ? this.content.scrollToTop() : {}))
        .catch((e) => console.error(e))
        .finally(() => (this.content != undefined ? this.content.scrollToTop() : {}));
    }
    this.navigationController.refreshUrl();
    //this.navigationController.setClickedRouterLink(false); ionViewDidEnterで使うので、ionViewDidEnterで初期化する
  }

  ionViewDidEnter() {
    let _this = this;
    let campaignId: number = null;
    // URLにてcampaign_idの指定があった場合、campaignIdをセット
    const requestParams = _this.requestParamsService.getRequestParams();
    if (requestParams.params.campaign_id) {
      campaignId = requestParams.params.campaign_id;
    }
    // 店舗詳細モーダルのpushPageにてcampaign_idの指定があった場合、campaignIdをセット
    if (_this.requestParams && _this.requestParams.campaign_id) {
      campaignId = _this.requestParams.campaign_id;
    }
    // campaignIdがセットされている場合、指定のキャンペーン位置までスクロールする
    if (campaignId !== null) {
      let id = setInterval(function () {
        // スクロール移動先の要素取得
        let scrollToElement = _this.campaignElements.filter(
          (campaign) => campaign.nativeElement.id.split('-')[1] === campaignId
        );
        // スクロール移動先要素が取得できた場合
        if (scrollToElement.length > 0) {
          // スクロール移動先までのスクロール量取得
          let scrollVal = _this.isShopDetailNav
            ? _this.shopDetailModalService.scrollToEle(scrollToElement[0].nativeElement.offsetParent.offsetTop)
            : _this.metaDataController.scrollToEle(scrollToElement[0].nativeElement.id);
          // キャンペーンの限定/本部タブが表示されている場合、タブの切り替えとスクロール位置の調整
          if (_this.pageData.coupon.length > 0 && _this.pageData.companyCoupon.length > 0) {
            // 指定したキャンペーンが本部で登録したキャンペーンの場合
            let companyCampaign = _this.pageData.companyCoupon.filter((campaign) => campaign.id === campaignId);
            if (companyCampaign.length > 0) {
              // タブを「限定」から「共通」に切り替え
              _this.isCheckShop = false;
              // 「限定」側要素の高さをスクロール量から除く
              scrollVal = scrollVal - _this.shopCampaignGroup.nativeElement.clientHeight;
            }
          }
          // スクロール位置調整
          _this.content.scrollToPoint(0, scrollVal);
        }

        clearInterval(id); //idを元にsetIntervalを削除
      }, 500);
    }

    this.navigationController.setClickedRouterLink(false);
  }

  ionViewDidLeave() {
    // iframe要素を全て再セットする（停止処理）
    this.stopVideo();
  }

  public async loadData() {
    this.pageData = await this.pds.getCampaignPageData(this.shopName);
    this.blockData = this.pageData.blockData;
    this.blockService.remakeBlockData(this.blockData);
    this.blockService.updateAltSalonName(this.pageData.altSalonName);
    this.salonData = await this.pds.getSalonData(this.shopName);

    if (!this.pageData.isDisplay && this.pds.getPreview()) {
      this.isDisplayPrevPage = false;
      this.pageDataForPub = await this.pds.getCampaignPageData(this.shopName, false);
      if (!this.pageDataForPub.isDisplay) {
        this.isDisplayPubPage = false;
      }
    }
    // この条件の場合は非公開状態
    if ((!this.salonData.isPublic || this.salonData.publishedDate == null) && !this.pds.getPreview()) {
      this.isShowPrivateMessage = true;
    }
    if (!this.pageData.isDisplay && !this.pds.getPreview()) {
      if (this.isShopDetailNav) {
        this.shopDetailModalService.toHome(this.shopName);
      } else {
        this.navigationController.toHome();
      }
    }
    this.pageTitle = this.pageData['pageTitle'];
    if (this.pageData['isDisplayEndTitle']) {
      this.pageTitle = this.pageTitle + '｜' + this.pageData['endTitle'];
    }
    this.metaDataController.updateTitleDescription(this.pageTitle, this.pageData['pageDescription']);
    this.metaDataController.deleteWebSiteStructuredData();
    this.metaDataController.updateOGTitleDescription(
      this.pageTitle,
      this.pageData['pageDescription'],
      this.navigationController.getCurrentUrl()
    );

    for (let i = 0; i < this.pageData.coupon.length; i++) {
      this.pageData.coupon[i]['imageHtml'] = this.sanitizer.bypassSecurityTrustHtml(
        this.pageData.coupon[i]['imageIframe']
      );
    }
    for (let i = 0; i < this.pageData.companyCoupon.length; i++) {
      this.pageData.companyCoupon[i]['imageHtml'] = this.sanitizer.bypassSecurityTrustHtml(
        this.pageData.companyCoupon[i]['imageIframe']
      );
    }
  }

  clickLink(link: string, requestParams: { pageName: string } = { pageName: null }) {
    if (this.isShopDetailNav) {
      // 店舗詳細モーダルで開いた場合
      this.shopDetailModalService.pushPage(this.shopName, link, requestParams);
    } else {
      // pageNameはモーダルでは使用しないので、パラメータを削除する
      delete requestParams['pageName'];
      // モーダルを閉じた状態でクリックした場合、通常のページ遷移を行う
      this.router.navigate(['/' + link], { queryParams: requestParams });
      this.navigationController.setClickedRouterLink(true);
    }
  }

  // 動画を止める
  stopVideo() {
    // ページ内動画要素を取得する
    let videoIframeContentList = this.videoIframeContentElement.toArray();
    // 取得した結果、動画要素が存在した場合
    if (videoIframeContentList.length > 0) {
      // 各動画要素のinnerHTML(iframe部分)を再セットする
      for (let videoIframeContent of videoIframeContentList) {
        videoIframeContent.nativeElement.innerHTML = videoIframeContent.nativeElement.innerHTML;
      }
    }
    // 動画ブロックをリセットする
    let appBlockList = this.appBlockElement.toArray();
    for (let appBlock of appBlockList) {
      appBlock.resetVideoBlock();
    }
  }
}
