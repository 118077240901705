import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root' // This makes the service a singleton
})
export class BlockService {
  altSalonName: string = '';
  public sideBarCategoryList = []; // メニューブロックでサイドバーを出すカテゴリーのリスト

  constructor(private sanitizer: DomSanitizer) {}

  public remakeBlockData(blockData) {
    for (let position in blockData) {
      for (let block of blockData[position]) {
        // <<<表示内容を整える>>>
        // 各ブロックでHTMLへの変換が必要な項目を変換する
        block.titleHTML = this.sanitizer.bypassSecurityTrustHtml(block.title);
        block.textHTML = this.sanitizer.bypassSecurityTrustHtml(block.text);
        block.imageHTML = this.sanitizer.bypassSecurityTrustHtml(block.imageIframe);
        if (block.price !== '' && block.price !== null) {
          block.price = '￥' + block.price.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
        }

        // <<<紹介ブロックの表示チェック>>>
        // 紹介ブロックの詳細エリア、画像エリア表示チェック
        block.isIntroductionTextInfoArea = true;
        block.isIntroductionVisualArea = true;
        if (block.blockType === 'introduction') {
          // 見出し、価格、テキストすべてが未入力の場合
          if (block.title == '' && block.price == '' && block.text == '') {
            // 詳細エリアを非表示にする
            block.isIntroductionTextInfoArea = false;
          }
          // 画像、動画すべてが未登録の場合
          if (block.image == '' && block.imageIframe == '') {
            // 画像エリアを非表示にする
            block.isIntroductionVisualArea = false;
          }
          // 画像、動画が非表示になっている場合
          if (!block.isDisplayVisual) {
            // 画像エリアを非表示にする
            block.isIntroductionVisualArea = false;
          }
        }

        // <<<ブロックグループを重複して表示しないようにするための処理>>>
        if (typeof block.isCreateBase === 'undefined') {
          // 描画の基準となるフラグに値がセットされていない場合、基準と判断してtrueをセットする
          block.isCreateBase = true;
          block.isDisplayGroup = false;
          // グループIDがセットされているブロックの場合
          if (block.groupId) {
            for (let blockOfGroup of blockData[position]) {
              // 他のブロックをチェックして、グループIDが一致するブロックは基準ではないと判断してfalseをセットする（isDisplayGroupは特に参照しないがパラメータ数を合わせるためにfalseをセット）
              if (blockOfGroup.id != block.id && blockOfGroup.groupId == block.groupId) {
                blockOfGroup.isCreateBase = false;
                blockOfGroup.isDisplayGroup = false;
              }
              // グループIDが一致するブロックをチェック
              if (blockOfGroup.groupId == block.groupId) {
                // 表ブロックで、見出しもしくはテキストのどちらかが入力済みのブロックを登録している場合
                if (blockOfGroup.blockType == 'table' && (blockOfGroup.title != '' || blockOfGroup.text != '')) {
                  // 表ブロック全体を表示すると判断して、基準となるブロックのisDisplayGroupにtrueをセットする
                  block.isDisplayGroup = true;
                }
                // 商品・メニューブロックで、表示項目が一つでも登録されている場合
                if (
                  blockOfGroup.blockType == 'introduction' &&
                  (blockOfGroup.title != '' ||
                    blockOfGroup.price != '' ||
                    blockOfGroup.text != '' ||
                    ((blockOfGroup.image != '' || blockOfGroup.imageIframe != '') && blockOfGroup.isDisplayVisual))
                ) {
                  // 表ブロック全体を表示すると判断して、基準となるブロックのisDisplayGroupにtrueをセットする
                  block.isDisplayGroup = true;
                }
                // メニュー参照ブロック
                if (blockOfGroup.blockType == 'referenceCategory' || blockOfGroup.blockType == 'referenceMenu') {
                  block.isDisplayGroup = true;
                }
                if (blockOfGroup.blockType === 'referenceCategory' && block.position === 'menu__conceptBlock') {
                  this.sideBarCategoryList.push(blockOfGroup);
                }
              }
            }
          }
        }

        // 店舗メニュー参照ブロック
        if (
          block.position === 'menu__conceptBlock' &&
          (block.blockType === 'referenceCategory' || block.blockType === 'referenceMenu')
        ) {
          if (block.blockType === 'referenceCategory') {
            block.categoryBlockId = block.id;
          } else {
            for (let b of blockData[position]) {
              if (block.newViewCategoryId == b.newViewCategoryId && b.blockType === 'referenceCategory') {
                block.categoryBlockId = b.id;
                break;
              }
            }
          }
        }
      }
    }
  }

  // altSalonNameを更新するメソッド
  updateAltSalonName(name: string) {
    this.altSalonName = name;
  }
}
