import { Component, OnDestroy, OnInit, ViewChild, ViewChildren, ElementRef, Input } from '@angular/core';

import { PagedataService } from '../services/pagedata.service';
import { NewsDetailPageData } from '../interfaces/newsDetailPageData.interface';
import { SalonData } from '../interfaces/salonData.interface';
import { initNewsDetailPageData, initSalonData } from '../initData/initData';
import { RequestParamsService } from '../services/requestParams.service';
import { NavigationController } from '../services/navigationController';
import { MetaDataController } from '../services/metaDataController';
import { Router } from '@angular/router';

import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NoticeBlurActiveFromHeader } from '../services/noticeBlurActiveFromHeader';
import { NoticeBlurActiveFromHeaderForModal } from '../services/noticeBlurActiveFromHeaderForModal';
import { Subscription } from 'rxjs';
import { IonContent } from '@ionic/angular';

import { MobilefooterbuttonsComponent } from '../mobilefooterbuttons/mobilefooterbuttons.component';
import { WindowService } from '../services/windowService';
import { ShopDetailModalService } from '../services/shopDetailModal.service';
import { BlockService } from '../services/block.service';

@Component({
  selector: 'app-newsdetail',
  templateUrl: './newsdetail.page.html',
  styleUrls: ['./newsdetail.page.scss']
})
export class NewsdetailPage implements OnInit, OnDestroy {
  @Input() shopName: string;
  @Input() isShopDetailNav: boolean = false;
  @Input() requestParams: any;

  @ViewChild(IonContent, { static: false }) content: IonContent;
  pageData: NewsDetailPageData = initNewsDetailPageData;
  public blockData: any = [];

  public salonData: SalonData = initSalonData;
  public isShowPrivateMessage: boolean = false;
  public privateMsgHtml: SafeHtml;
  private subscription: Subscription;
  blurActiveFlgFromHeader: boolean = false;
  blurActiveModalFlgFromHeader: boolean = false;
  pageDataForPub: NewsDetailPageData; // プレビュー時使用
  isDisplayPrevPage: boolean = true;
  isDisplayPubPage: boolean = true;
  isNews: boolean = true;
  @ViewChild(MobilefooterbuttonsComponent, { static: false })
  private mfbc: MobilefooterbuttonsComponent;

  public blockAreaListForAddBlock: any = {};
  @ViewChildren('appBlock') appBlockElement;
  @ViewChildren('videoIframeContent') videoIframeContentElement;
  public pageTitle: string;
  public isCompanyContents: boolean = false;

  constructor(
    public pds: PagedataService,
    private requestParamsService: RequestParamsService,
    private navigationController: NavigationController,
    private sanitizer: DomSanitizer,
    private metaDataController: MetaDataController,
    private noticeBlurActiveFromHeader: NoticeBlurActiveFromHeader,
    private noticeBlurActiveFromHeaderForModal: NoticeBlurActiveFromHeaderForModal,
    private elem: ElementRef,
    public windowService: WindowService,
    private shopDetailModalService: ShopDetailModalService,
    public router: Router,
    private blockService: BlockService
  ) {}

  ngOnInit() {
    // イベント登録
    // サービスで共有しているデータが更新されたら発火されるイベントをキャッチする
    this.subscription = this.noticeBlurActiveFromHeader.sharedDataSource$.subscribe((msg) => {
      this.blurActiveFlgFromHeader = msg;
    });
    this.subscription = this.noticeBlurActiveFromHeaderForModal.sharedDataSource$.subscribe((msg) => {
      this.blurActiveModalFlgFromHeader = msg;
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ionViewWillEnter() {
    // 戻るボタン・スワイプバックでこのページについた場合はスクロール位置を修正（データが残っているのでデータの読み直しはしない）
    // 進むボタン時はコンポーネント初期化されてデータがないので、elseへ
    if (!this.navigationController.getClickedRouterLink() && this.pageData.title != null) {
    } else {
      const promise = this.load();
      promise
        .then((result) => (this.content != undefined ? this.content.scrollToTop() : {}))
        .catch((e) => console.error(e))
        .finally(() => (this.content != undefined ? this.content.scrollToTop() : {}));
    }
    this.navigationController.setClickedRouterLink(false);
    this.navigationController.refreshUrl();
  }

  ionViewDidLeave() {
    // iframe要素を全て再セットする（停止処理）
    this.stopVideo();
  }

  private async load() {
    let params = this.requestParamsService.getRequestParams();
    let newsId = params.newsId;
    if (newsId == null && this.isShopDetailNav) {
      newsId = this.requestParams.news_id;
    }
    this.pageData = await this.pds.getNewsDetailPageData(this.shopName, newsId);
    this.isCompanyContents = this.pageData['salonId'] !== this.pageData.news['salonId'];
    this.blockData = this.pageData.blockData;
    this.blockService.remakeBlockData(this.blockData);
    this.blockService.updateAltSalonName(this.pageData.altSalonName);
    this.salonData = await this.pds.getSalonData(this.shopName);
    this.isNews = this.pageData.news.id !== null && typeof this.pageData.news.id !== 'undefined';

    // この条件の場合は非公開状態
    if ((!this.salonData.isPublic || this.salonData.publishedDate == null) && !this.pds.getPreview()) {
      this.isShowPrivateMessage = true;
    }
    if (this.pds.getPreview()) {
      if (
        !this.pageData.isDisplay ||
        this.pageData.newsPrev.id === null ||
        typeof this.pageData.newsPrev.id === 'undefined'
      ) {
        this.isDisplayPrevPage = false;
        this.pageDataForPub = await this.pds.getNewsDetailPageData(this.shopName, newsId, false);
        if (
          !this.pageDataForPub.isDisplay ||
          this.pageDataForPub.news.id === null ||
          typeof this.pageDataForPub.news.id === 'undefined'
        ) {
          this.isDisplayPubPage = false;
        }
      }
    } else {
      if (!this.pageData.isDisplay || !this.isNews) {
        if (this.isShopDetailNav) {
          this.shopDetailModalService.toHome(this.shopName);
        } else {
          this.navigationController.toHome();
        }
      }
    }
    this.pageTitle = this.pageData['pageTitle'];
    if (this.pageData['isDisplayEndTitle']) {
      this.pageTitle = this.pageTitle + '｜' + this.pageData['endTitle'];
    }
    this.metaDataController.updateTitleDescription(this.pageTitle, this.pageData['pageDescription']);
    this.metaDataController.deleteWebSiteStructuredData();
    this.metaDataController.updateOGTitleDescription(
      this.pageTitle,
      this.pageData['pageDescription'],
      this.navigationController.getCurrentUrl(),
      this.pageData.news.title
    );
    if (this.pageData.news.imageDisplayType == 4) {
      this.pageData.news.imageHtml = this.sanitizer.bypassSecurityTrustHtml(this.pageData.news.imageIframe);
    }
  }

  clickLink(link, requestParams = {}) {
    if (this.isShopDetailNav) {
      // 店舗詳細モーダルで開いた場合
      this.shopDetailModalService.pushPage(this.shopName, link, requestParams);
    } else {
      // pageNameはモーダルでは使用しないので、パラメータを削除する
      delete requestParams['pageName'];
      // モーダルを閉じた状態でクリックした場合、通常のページ遷移を行う
      this.router.navigate(['/' + link], { queryParams: requestParams });
      this.navigationController.setClickedRouterLink(true);
    }
  }

  // 動画を止める
  stopVideo() {
    // ページ内動画要素を取得する
    let videoIframeContentList = this.videoIframeContentElement.toArray();
    // 取得した結果、動画要素が存在した場合
    if (videoIframeContentList.length > 0) {
      // 各動画要素のinnerHTML(iframe部分)を再セットする
      for (let videoIframeContent of videoIframeContentList) {
        videoIframeContent.nativeElement.innerHTML = videoIframeContent.nativeElement.innerHTML;
      }
    }
    // 動画ブロックをリセットする
    let appBlockList = this.appBlockElement.toArray();
    for (let appBlock of appBlockList) {
      appBlock.resetVideoBlock();
    }
  }
}
