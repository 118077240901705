<ion-content
  *ngIf="(pageData.isDisplay && !isShowPrivateMessage) || !isDisplayPrevPage"
  class="overflow_scroll"
  id="staff_window_top"
  name="blur_aria"
  [class.blur_active]="(!isShopDetailNav && blurActiveFlgFromHeader) || (isShopDetailNav && blurActiveModalFlgFromHeader)"
  [scrollEvents]="true"
  [attr.data-page_title]="pageTitle"
  [attr.data-page_description]="pageData.pageDescription"
  (ionScroll)="mfbc.scrollEvent(content)"
  [class.__modalStyle__]="isShopDetailNav"
>
  <main class="main mainStaff content_top">
    <!-- スタッフ一覧ページ　見出し -->
    <section class="staffHeading sec">
      <div class="breadcrumbs" *ngIf="pageData.isDisplayBreadcrumbs; else elseIsBreadcurmbs">
        <ng-container *ngFor="let breadcrumbsData of pageData.breadcrumbsList">
          <span
            class="breadcrumbs_item home"
            *ngIf="breadcrumbsData.url == 'home'; else elseBreadcurmbsContent"
            (click)="clickLink( 'home' )"
            [class.__modalStyle__]="isShopDetailNav"
          ></span>
          <ng-template #elseBreadcurmbsContent>
            <label class="breadcrumbs_arrow" [class.__modalStyle__]="isShopDetailNav"> > </label>
            <span
              class="breadcrumbs_item"
              (click)="clickLink( breadcrumbsData.url )"
              [class.__modalStyle__]="isShopDetailNav"
              >{{ breadcrumbsData.title }}</span
            >
          </ng-template>
        </ng-container>
      </div>
      <ng-template #elseIsBreadcurmbs>
        <span href="javascript:void(0);" class="staffHeading__backLink" (click)="navigationController.goBack()"><</span>
      </ng-template>

      <div *ngIf="!isDisplayPrevPage" class="hiddenPageAlert">
        <div *ngIf="isDisplayPubPage" class="hiddenPageAlert__title__ForSave">このページは非表示予定です</div>
        <div *ngIf="!isDisplayPubPage" class="hiddenPageAlert__title__ForPublic">このページは現在非表示中です</div>
      </div>

      <div class="staffHeading_container secContainer">
        <h2 class="staffHeading__heading secHeading" [class.__modalStyle__]="isShopDetailNav">{{ pageData.title }}</h2>
      </div>
      <div
        class="staffBlock blockArea"
        *ngIf="blockData['staff__titleBlock'] && blockData['staff__titleBlock'].length > 0"
      >
        <app-block
          [shopName]="this.shopName"
          [isShopDetailNav]="isShopDetailNav"
          [blockData]="blockData['staff__titleBlock']"
          [position]="'staff__titleBlock'"
          #appBlock
        ></app-block>
      </div>
      <div class="staffHeading_container secContainer">
        <p
          class="staffHeading__desc"
          [innerHTML]="pageData.concept | htmlSanitize"
          [class.__modalStyle__]="isShopDetailNav"
        ></p>
      </div>
      <div
        class="staffBlock blockArea"
        *ngIf="blockData['staff__conceptBlock'] && blockData['staff__conceptBlock'].length > 0"
      >
        <app-block
          [shopName]="this.shopName"
          [isShopDetailNav]="isShopDetailNav"
          [blockData]="blockData['staff__conceptBlock']"
          [position]="'staff__conceptBlock'"
          #appBlock
        ></app-block>
      </div>
      <!-- /.staffHeading_container -->
    </section>
    <!-- /.staffHeading -->

    <!-- スタッフ一覧コンテンツ -->
    <section class="staffContent sec">
      <div class="staffContent_container">
        <!-- スタッフ　個別 -->
        <div *ngIf="pageData.isDisplayAllStaffImage; else elseContent">
          <div class="staffContent_staff" *ngFor="let c of pageData.staffPerson">
            <!-- スタッフ画像 名前・役職 -->
            <div class="staffContent_staff__info">
              <div *ngIf="c.photo" class="staffContent_staff__info__visual ratio_1x1 secContent">
                <img
                  class="staffContent_staff__info__inner"
                  src="{{ c.photo }}"
                  [alt]="pageData.altSalonName + 'の' + c.name + 'の写真'"
                />
              </div>
              <!-- /.staffContent_staff__visual -->

              <!-- スタッフ情報 -->
              <div class="staffContent_staff__info__nameTitle secContent" *ngIf="c.photo; else noPhotoInfo">
                <h3 class="staffContent_staff__info__nameTitle__name jpfont" [class.__modalStyle__]="isShopDetailNav">
                  {{ c.name }}
                </h3>
                <h3
                  class="staffContent_staff__info__nameTitle__staffPosition jpfont"
                  [class.__modalStyle__]="isShopDetailNav"
                  *ngIf="c.staffPosition"
                >
                  {{ c.staffPosition }}
                </h3>
              </div>
              <ng-template #noPhotoInfo>
                <div class="staffContent_staff__info__nameTitle secContent noPhoto">
                  <h3 class="staffContent_staff__info__nameTitle__name jpfont" [class.__modalStyle__]="isShopDetailNav">
                    {{ c.name }}
                  </h3>
                  <h3
                    class="staffContent_staff__info__nameTitle__staffPosition jpfont"
                    [class.__modalStyle__]="isShopDetailNav"
                    *ngIf="c.staffPosition"
                  >
                    {{ c.staffPosition }}
                  </h3>
                </div>
              </ng-template>
            </div>
            <!-- /.staffContent_staff__info -->

            <!-- メッセージ&ボタンエリア -->
            <div class="staffContent_staff__messageBtn">
              <!-- メッセージ&ボタンエリア内 スタッフ名と役職。最初はかくす。 -->
              <div class="staffContent_staff__messageBtn__nameTitle">
                <h3
                  class="staffContent_staff__messageBtn__nameTitle__name jpfont"
                  [class.__modalStyle__]="isShopDetailNav"
                >
                  {{ c.name }}
                </h3>
                <h3
                  class="staffContent_staff__info__nameTitle__staffPosition jpfont"
                  [class.__modalStyle__]="isShopDetailNav"
                  *ngIf="c.staffPosition"
                >
                  {{ c.staffPosition }}
                </h3>
              </div>
              <!-- /.staffContent_staff__messageBtn__nameTitle -->

              <!-- メッセージ&ボタンエリア内 メッセージ部分。 -->
              <div
                class="staffContent_staff__messageBtn__message"
                [class.__modalStyle__]="isShopDetailNav"
                *ngIf="c.introduction"
              >
                <p [innerHTML]="c.introduction"></p>
              </div>
              <!-- /.staffContent_staff__messageBtn__message -->

              <!-- メッセージ&ボタンエリア スタッフ指名予約 詳細リンクボタン -->
              <div class="staffContent_staff__messageBtn__btn secContent adjustContent">
                <div class="staffContent_staff__messageBtn__btn__detail">
                  <span
                    class="staffContent_staff__messagebtn__area__btn__detail__link btn_checkDetail"
                    (click)="clickLink( 'staffdetail' ,{ s: salonName, staff_id: c.id, p: isPreview, key: paramKey })"
                    [class.__modalStyle__]="isShopDetailNav"
                    >詳細を見る</span
                  >
                </div>
                <!-- /.staffContent_staff__messageBtn__btn__detail -->
                <div
                  class="staffContent_staff__messageBtn__btn__reserve"
                  *ngIf="c.isDisplayReserveLink && c.reserveDealType != 1 && !c.invalidPublic"
                >
                  <span
                    (click)="clickBooking(c)"
                    class="staffContent_staff__messagebtn__area__btn__reserve__bookShimei btn_bookShimei"
                    [class.__modalStyle__]="isShopDetailNav"
                    >指名予約する
                  </span>
                </div>
                <!-- /.staffContent_staff__messageBtn__btn__reserve -->
              </div>
              <!-- /.staffContent_staff__messageBtn__btn -->
            </div>
            <!-- /.staffContent_staff__messageBtn -->
          </div>
          <!-- /.staffContent_staff -->
        </div>

        <!-- スタッフ写真なしのバージョン -->
        <ng-template #elseContent>
          <div class="staffContent_staff" *ngFor="let c of pageData.staffPerson">
            <!-- メッセージ&ボタンエリア -->
            <div class="staffContent_staff__messageBtn staffContent_staff__messageBtn__elseContent">
              <!-- メッセージ&ボタンエリア内 メッセージ部分。 -->
              <div class="staffContent_staff__messageBtn__message" *ngIf="c.introduction">
                <h3 class="staffContent_staff__info__nameTitle__name__elseContent">{{ c.name }}</h3>
                <p>{{ c.introduction }}</p>
              </div>
              <!-- /.staffContent_staff__messageBtn__message -->

              <!-- メッセージ&ボタンエリア スタッフ指名予約 詳細リンクボタン -->
              <div class="staffContent_staff__messageBtn__btn secContent adjustContent">
                <div class="staffContent_staff__messageBtn__btn__detail">
                  <span
                    routerLink="/staffdetail"
                    [queryParams]="{ s: salonName, staff_id: c.id, p: isPreview, key: paramKey }"
                    class="staffContent_staff__messagebtn__area__btn__detail__link btn_checkDetail btn_checkDetail__elseContent"
                    (click)="navigationController.setClickedRouterLink(true)"
                    [class.__modalStyle__]="isShopDetailNav"
                    >詳細を見る</span
                  >
                </div>
                <!-- /.staffContent_staff__messageBtn__btn__detail -->
                <div
                  class="staffContent_staff__messageBtn__btn__reserve"
                  *ngIf="c.isDisplayReserveLink && c.reserveDealType != 1 && !c.invalidPublic"
                >
                  <span
                    (click)="clickBooking(c)"
                    class="staffContent_staff__messagebtn__area__btn__reserve__bookShimei btn_bookShimei"
                    [class.__modalStyle__]="isShopDetailNav"
                    >指名予約する
                  </span>
                </div>
                <!-- /.staffContent_staff__messageBtn__btn__reserve -->
              </div>
              <!-- /.staffContent_staff__messageBtn__btn -->
            </div>
            <!-- /.staffContent_staff__messageBtn -->
          </div>
          <!-- /.staffContent_staff -->
        </ng-template>
      </div>
      <!-- /.staffContent_container -->
    </section>
    <!-- /.staffContent -->
  </main>
  <!-- /.mainStaff -->

  <app-footer [shopName]="this.shopName" [isShopDetailNav]="isShopDetailNav" class="appFooter"></app-footer>
</ion-content>
<app-private
  class="ion-page"
  *ngIf="isShowPrivateMessage"
  [shopName]="this.shopName"
  [isShopDetailNav]="isShopDetailNav"
  [privateMsgHtml]="pageData.privateMsg | htmlSanitize"
></app-private>
<app-mobilefooterbuttons (scrollToTopEvent)="windowService.scrollToTop(content)"></app-mobilefooterbuttons>
<app-mobilefooter
  *ngIf="!isShowPrivateMessage && !isShopDetailNav"
  class="applike"
  [blurActiveFlg]="blurActiveFlgFromHeader"
  [shopName]="this.shopName"
  [isShopDetailNav]="isShopDetailNav"
></app-mobilefooter>
